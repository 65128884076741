
import {RepresentativeDetails} from '../RepresentativeDetails/representativeDetails.model';

import {ProjectAttachment} from '../ProjectAttachment/projectAttachment.model';

import {serializable, alias, object, list, primitive} from 'serializr';

export class SuggestedProject { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('reg_number', primitive()))
	regNumber?: string;

	@serializable(alias('temple_name', primitive()))
	templeName?: string;

	@serializable(alias('created_at', primitive()))
	createdAt?: string;

	@serializable(alias('updated_at', primitive()))
	updatedAt?: string;

	@serializable(alias('status', primitive()))
	status?: string;

	@serializable(alias('project_attachments', list(object(ProjectAttachment))))
	projectAttachments?: ProjectAttachment[] = [];

	@serializable(alias('representative_details', object(RepresentativeDetails)))
	representativeDetails?: RepresentativeDetails;

}