import React, { FC, useEffect, useState } from 'react'
import "./internalUserForm.scss"
import { Form, Formik, Field, ErrorMessage, FormikHelpers } from "formik";
import InputField from "../../../shared/components/InputField";
import { Button, Modal, Checkbox, Switch } from "antd";
import { createInternalUserValidation } from "./creatInternalUserValidation";
import Error from "../../../shared/components/Error";
import { User } from "../../../models/user.model";
import InternalUserService from '../../../services/InternalUserService/internalUser.service';
import PhoneNumberInput from '../../../shared/components/PhoneNumberInput';
import { parsePhoneNumber } from 'react-phone-number-input';

interface InternalUserFormProps {
    showModal: boolean;
    setShowModal: (visiblity: boolean, response?: boolean) => void;
    user?: User;
}

const InternalUserForm: FC<InternalUserFormProps> = (props) => {
    const { showModal, setShowModal, user } = props;

    const {
        createInternalUsers,
        updateInternalUsers,
        internalUsersSubmitting,
    } = InternalUserService()

    const [initialValues, setInitialValues] = useState<User>({
        username: "",
        email: "",
        mobileNumber: "",
        permissions: [],
        status: true
    })

    useEffect(() => {
        setInitialValues(
            {
                ...user,
                mobileNumber: user?.id ? (user?.isdCode || '') + user?.mobileNumber : undefined,
                status: user?.status ?? true
            }
        )
    }, [user])

    const handleSubmit = async (values: User, helpers: FormikHelpers<User>) => {
        let response;
        if (values.id) {
            response = await updateInternalUsers({
                ...values,
                isdCode: '+' + parsePhoneNumber(values.mobileNumber || '')?.countryCallingCode as string,
                mobileNumber: parsePhoneNumber(values.mobileNumber || '')?.nationalNumber as string
            })
        } else {
            response = await createInternalUsers({
                ...values,
                isdCode: "+" + parsePhoneNumber(values.mobileNumber || '')?.countryCallingCode as string,
                mobileNumber: parsePhoneNumber(values.mobileNumber || '')?.nationalNumber as string
            })
        }
        if (!response) return
        helpers.resetForm()
        setShowModal(false, true)
    }

    const checkboxOptions = [
        { label: 'Projects', value: 'projects' },
        { label: 'Donors', value: 'donors' },
        { label: 'Payments', value: 'payments' },
        { label: 'Representative', value: 'representative' },
    ];

    return (
        <div className="internal-user-form">
            <Modal title={
                <div className="modal-title">
                    <h2>{user?.id ? 'Edit ' : 'New '} Internal User</h2>
                    <i className="icon-close" onClick={() => setShowModal(false)} />
                </div>
            }
                visible={showModal}
                onCancel={() => setShowModal(false)}
                className="create-project-modal create-payment-modal"
            >
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={createInternalUserValidation}
                    enableReinitialize
                >
                    {({ values, setFieldValue, isValid }) => {
                        return (
                            <Form className="create-project-form create-internal-user">
                                <div className="form-field">
                                    <InputField type="text"
                                        name="username"
                                        placeholder="Type name"
                                        title="User Name"
                                    />
                                </div>
                                <div className="form-field">
                                    <InputField type="email"
                                        name="email"
                                        placeholder="Type email"
                                        title="Email ID"
                                    />
                                    <div className="sub-title mt-2">
                                        <i className="icon-info" /> User will receive a temporary password to the specified email
                                    </div>
                                </div>
                                <div className="form-field">
                                    <PhoneNumberInput
                                        title='Phone Number'
                                        name={'mobileNumber'}
                                        value={values.mobileNumber}
                                        onChange={(value) => {
                                            setFieldValue("mobileNumber", value)
                                        }}
                                    />
                                </div>
                                <div className="form-field">
                                    <div className="label">
                                        <label>Access</label>
                                    </div>
                                    <Field name="permissions">
                                        {() =>
                                            <>
                                                <Checkbox.Group options={checkboxOptions}
                                                    onChange={(values) =>
                                                        setFieldValue("permissions", values)
                                                    }
                                                    value={values?.permissions}
                                                />
                                                <ErrorMessage name={"permissions"}>
                                                    {(message: string) => <Error message={message} />}
                                                </ErrorMessage>
                                            </>
                                        }
                                    </Field>
                                </div>
                                <div className="form-field">
                                    <Field name="status">
                                        {() =>
                                            <div className={`internal-user-status ${values?.status ? 'active' : 'inactive'}`}>
                                                <span className="font-bold">{values?.status ? 'Active' : 'Inactive'}</span>
                                                <Switch defaultChecked
                                                    checked={values?.status}
                                                    onChange={(checked) => {
                                                        setFieldValue('status', checked)
                                                    }}
                                                />
                                            </div>
                                        }
                                    </Field>
                                </div>
                                <div className="form-field">
                                    <Button type="primary"
                                        htmlType="submit"
                                        loading={internalUsersSubmitting}
                                        disabled={!isValid}
                                    >
                                        {user?.id ? 'Update ' : 'Create '} User
                                    </Button>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </Modal>
        </div>
    )
}

export default InternalUserForm;