import React, { FC } from "react";
import { User } from "../../../models/user.model";
import UserService from "../../../services/AuthService/auth.service";
import { ResetPasswordValidationSchema } from "./validation";
import { Form, Formik } from "formik";
import InputField from "../../../shared/components/InputField";
import { Button } from "antd";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { appRoutes } from "../../../routes/routeConstants/appRoutes";
import "./resetPassword.scss";

interface ResetPasswordProps {}

const ResetPassword: FC<ResetPasswordProps> = (props) => {
  const {} = props;

  const location = useLocation();

  const history = useHistory();

  const { resetPassword, loading } = UserService();

  const email = new URLSearchParams(location.search).get("email");

  const handleSubmit = async (values: User) => {
    const user = Object.assign(new User(), {
      ...values,
      email
    });
    if (await resetPassword(user)) {
      history.push(appRoutes.LOGIN);
    }
  };

  const formikProps = {
    initialValues: new User(),
    validationSchema: ResetPasswordValidationSchema,
    onSubmit: handleSubmit,
  };

  if (!email) {
    history.push(appRoutes.LOGIN);
  }

  return (
    <div className="reset-password">
      <Formik {...formikProps}>
        {({ dirty, isValid }) => (
          <Form>
            <h2 className="font-bold">Reset Password</h2>
            <InputField
              title="Update Password"
              placeholder="Enter Password"
              name="password"
              type="password"
            />
            <InputField
              title="Confirm Password"
              placeholder="Enter confirm Password"
              name="confirmPassword"
              type="password"
            />
            <Button
              htmlType="submit"
              type="primary"
              loading={loading}
              disabled={!dirty || !isValid}
            >
              Update and Proceed
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ResetPassword;
