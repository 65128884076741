import {serializable, alias, object, list, primitive} from 'serializr';

export class ProjectStatsModel{

    @serializable(alias('proposed', primitive()))
    proposed: number = 0;

    @serializable(alias('planned', primitive()))
    planned: number = 0;

    @serializable(alias('active', primitive()))
    active: number = 0;

    @serializable(alias('completed', primitive()))
    completed: number = 0;

    @serializable(alias('scrapped', primitive()))
    scrapped: number = 0;

}

export class UserStats{

    @serializable(alias('total_donors', primitive()))
    totalDonors: number = 0;

    @serializable(alias('active', primitive()))
    active: number = 0;

    @serializable(alias('inactive', primitive()))
    inactive: number = 0;

}

export class DashboardStatsModel {

    @serializable(alias('project_stats', object(ProjectStatsModel)))
    projectStats: ProjectStatsModel = new ProjectStatsModel();

    @serializable(alias('donor_stats', object(UserStats)))
    donorStats: UserStats = new UserStats();

    @serializable(alias('area_rep_stats', object(UserStats)))
    areaRepStats: UserStats = new UserStats();

    @serializable(alias('internal_users', object(UserStats)))
    internalUsersStats: UserStats = new UserStats();

}

export class MonthStatsModel {
    @serializable(alias('month', primitive()))
    month?: string;

    @serializable(alias('total_payment', primitive()))
    totalPayment: number = 0;

    @serializable(alias('online_payment', primitive()))
    onlinePayment: number = 0;

    @serializable(alias('offline_payment', primitive()))
    offlinePayment: number = 0;

    @serializable(alias('one_time_payment', primitive()))
    oneTimePayment: number = 0;
}

export class DonationStatsModel {

    @serializable(alias('total_donors', primitive()))
    totalDonors: number = 0;

    @serializable(alias('current_month_donations', primitive()))
    currentMonthDonations: number = 0;

    @serializable(alias('total_donations_current_yr', primitive()))
    totalDonationsCurrentYear: number = 0;

    @serializable(alias('donation_stats', list(object(MonthStatsModel))))
    donationStats: Array<MonthStatsModel> = [];

}

export class DonorStatsModel {

    @serializable(alias('month', primitive()))
    month?: string;

    @serializable(alias('donors_signed_up', primitive()))
    donorsSignedUp: number = 0;

}