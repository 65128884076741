import React, { FC } from 'react'
import "./datepickerField.scss"
import {DatePicker} from "antd";
import {ErrorMessage, Field} from "formik";
import Error from "../Error";
import moment from "moment";

interface DatepickerFieldProps {
    name: string;
    title?: string;
    setFieldValue?: Function;
    placeholder?: string;
    value?: any;
    onChange?: (value: any, dateString: any) => void;
    disabled?: boolean;
    className?:string;
}

const DatepickerField: FC<DatepickerFieldProps> = (props) => {
    const {
        name,
        title,
        setFieldValue,
        placeholder,
        onChange,
        value,
        disabled,
        className
    } = props;

    function disabledDate(current: any) {
        // Can not select days before today and today
        return current && current < moment().endOf('day');
    }

    function range(start: number, end: number) {
        const result = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    }

    function disabledDateTime() {
        return {
            disabledHours: () => range(0, 24).splice(4, 20),
            disabledMinutes: () => range(30, 60),
            disabledSeconds: () => [55, 56],
        };
    }

    return (
        <Field name={name}>
            {({
                  field,
                  form: { touched, errors, setFieldTouched },
                  meta,
              }: any) => {
                return (
                    <div className={`datepicker-field`}>
                        {title && (
                            <div className="dropdown-field__title">{title}</div>
                        )}
                        <DatePicker format="DD/MM/YYYY"
                                    // disabledDate={disabledDate}
                                    placeholder={placeholder}
                                    //suffixIcon={<i className='icon-date'/>}
                                    onChange={onChange}
                                    value={value ?? null}
                                    disabled={disabled}
                                    className={className}

                        />
                        <ErrorMessage name={name}>
                            {(message: string) => (
                                // @ts-ignore
                                <Error className={`${name}__error`}
                                       message={message}
                                />
                            )}
                        </ErrorMessage>
                    </div>
                );
            }}
        </Field>
    )
}

export default DatepickerField;