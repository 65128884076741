import React, {FC, useEffect, useState} from 'react'
import "./dashboardDonorChart.scss"
import Card from "../../../shared/components/Card"
import {Col, Row} from "antd";
import { Bar } from 'react-chartjs-2';
import DashboardService from "../../../services/DashboardService/dashboard.service";
import moment from "moment";

interface DashboardDonorChartProps {

}

const DashboardDonorChart: FC<DashboardDonorChartProps> = (props) => {
    const { } = props;

    const { fetchDonorStats, donorStats, fetchDashboardStats, dashboardStats } = DashboardService();

    const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const [chartData, setChartData] = useState<number[]>([]);

    useEffect(()=>{
        fetchDonorStats().then();
        fetchDashboardStats().then();
    },[])

    useEffect(()=>{
        if(donorStats.length)
            setChartData(labels.map(label => {
                const monthStat = donorStats.find(donorStat => label === moment(donorStat.month).format('MMM'))
                return monthStat ? monthStat.donorsSignedUp : 0
            }))
    },[donorStats])

    const data = {
        labels: labels,
        datasets: [
            {
                label: '# of donors',
                data: chartData,
                backgroundColor: [
                    '#773AF8',
                ],
                barThickness: 5,
                borderRadius: 3,
            },
        ],
    };

    const options:any = {
        plugins:{
            legend:{
                display: false
            }
        },
        scales: {
            y: {
                grid: {
                    drawBorder: false,
                },
                ticks: {
                    padding: 10,
                    stepSize: 10,
                    beginAtZero: true,
                },
            },
            xAxes: {
                grid:{
                    display: false
                }
            }
        },
    };

    return (
        <div className="dashboard-donor-chart">
            <Card>
                <Row>
                    <Col span={16}>
                        <span className={"sub-title"}>Total Donors</span>
                        <span className={"dashboard-donor-chart__count font-bold"}>
                            {dashboardStats.donorStats.totalDonors}
                        </span>
                    </Col>
                    <Col span={4}>
                        <div className="active-stats">
                            <div className={"stats-label"}>Active</div>
                            <div className={"stats-value"}>
                                {dashboardStats.donorStats.active}
                            </div>
                        </div>
                    </Col>
                    <Col span={4}>
                        <div className="inactive-stats">
                            <div className={"stats-label"}>Inactive</div>
                            <div className={"stats-value"}>
                                {dashboardStats.donorStats.inactive}
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className="dashboard-donor-chart__container">
                    <Bar data={data} options={options} />
                </div>
            </Card>
        </div>
    )
}

export default DashboardDonorChart;