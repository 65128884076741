import { CreateReducer } from "../../shared/utils/createReducer";
import { ActionProps } from "../../shared/types/action.type";
import {Project} from "../../models/Project/project.model";
import {PROJECT_UPDATE} from "../definitions/projectConstants";

export interface ProjectState {
    projectStore?: Project;
}

const initState: ProjectState = {
    projectStore: new Project()
};

const projectReducer = CreateReducer(initState, {
    [PROJECT_UPDATE](state: ProjectState, action: ActionProps): ProjectState {
        const { project } = action?.payload;
        return {
            ...state,
            projectStore: project
        };
    }
});

export default projectReducer;



