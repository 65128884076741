import { Button } from "antd";
import { Form, Formik } from "formik";
import React, { FC } from "react";
import { User } from "../../../models/user.model";
import UserService from "../../../services/AuthService/auth.service";
import InputField from "../../../shared/components/InputField";
import "./forgotPassword.scss";
import { ForgotPasswordValidationSchema } from "./validation";

interface ForgotPasswordProps {}

const ForgotPassword: FC<ForgotPasswordProps> = (props) => {
  const {} = props;

  const { setResetCode, loading } = UserService();

  const handleSubmit = async (values: User) => {
    const user = Object.assign(new User(), values);
    await setResetCode(user);
  };

  const formikProps = {
    initialValues: new User(),
    validationSchema: ForgotPasswordValidationSchema,
    onSubmit: handleSubmit,
  };
  return (
    <div className="forgot-password">
      <Formik {...formikProps}>
        {({ dirty, isValid }) => (
          <Form>
            <h2 className="font-bold">Forgot Password</h2>
            <InputField
              title="Enter Registered Email ID"
              placeholder="Type here"
              name="email"
              type="text"
            />
            <Button
              htmlType="submit"
              type="primary"
              loading={loading}
              disabled={!dirty || !isValid}
            >
              Send Reset Code
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ForgotPassword;
