import React from "react";
import { Formik, Form } from "formik";
import InputField from "../../../shared/components/InputField";
import { validationSchema } from "./LoginValidation";
import { Button } from "antd";
import UserService from "../../../services/AuthService/auth.service";
import { useHistory, withRouter } from "react-router-dom";
import {appRoutes} from "../../../routes/routeConstants/appRoutes";
import "./login.scss";
import { User } from "../../../models/user.model";

const LoginForm = () => {
  const { loading, loginUser } = UserService();

  const history = useHistory();

  const onSubmit = async (values: User) => {
    const user = Object.assign(new User(), values);
    await loginUser(user);
  };

  const handleForgotPassword = () => {
    history.push(appRoutes.FORGOT_PASSWORD);
  };

  return (
    <div className="login-form">
      <Formik
        initialValues={new User()}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ dirty, isValid }) => (
          <Form>
            <h2 className="font-bold">Login to admin portal</h2>
            <InputField
              title="Email ID"
              type="email"
              name="email"
              placeholder="Enter email"
            />
            <InputField
              title="Password"
              type="password"
              name="password"
              placeholder="Enter password"
            />
            <div className="forget-password__link mb-5">
              <label
                htmlFor="forget-password"
                className="cursor-pointer link"
                onClick={handleForgotPassword}
              >
                Forgot Password ?
              </label>
            </div>
            <Button
              htmlType="submit"
              type="primary"
              disabled={!isValid || !dirty}
              loading={loading}
            >
              Login
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default withRouter(LoginForm);
