import { Col, Input, Pagination, Progress, Row, Select, Table, TablePaginationConfig } from "antd";
import React, { FC, useEffect } from "react";
import { ProjectTypes } from "../../../enums/projectTypes";
import projectService from "../../../services/ProjectService/project.service";
import moment from "moment"
import "./projectsTable.scss";
import { Project } from "../../../models/Project/project.model";
import { generatePath, useHistory } from "react-router-dom";
import { appRoutes } from "../../../routes/routeConstants/appRoutes";
import AppTable from "../../../shared/components/AppTable";
import { PaginationModel } from "../../../models/Pagination/pagination.model";

const { Option } = Select;

interface ProjectsTableProps {
  type: ProjectTypes;
  loading: boolean;
  projects: Array<Project>;
  fetchProjects: Function;
  pagination?: PaginationModel
}

const columns1 = [
  // {
  //   title: "S.NO",
  //   dataIndex: "name",
  //   key: "name",
  //   render: (_: any, __: any, index: number) => index + 1,
  //   sorter: (a: any, b: any) => a.id - b.id,
  // },
  {
    title: "Reg Number",
    dataIndex: "regNumber",
    key: "regNumber",
  },
  {
    title: "Temple Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Location",
    dataIndex: "location",
    key: "location",
  },
  {
    title: "Created On",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (date: string) => moment(date).format("DD/MM/YYYY")
  },
];

const columns2 = [
  // {
  //   title: "S.NO",
  //   dataIndex: "name",
  //   key: "name",
  //   render: (_: any, __: any, index: number) => index + 1,
  //   sorter: (a: any, b: any) => a.id - b.id,
  // },
  {
    title: "Reg Number",
    dataIndex: "regNumber",
    key: "regNumber",
  },
  {
    title: "Temple Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Location",
    dataIndex: "location",
    key: "location",
  },
  {
    title: "Start Date",
    dataIndex: "created_at",
    key: "created_at",
    render: (date: string) => moment(date).format("DD/MM/YYYY")
  },
  {
    title: "End Date",
    dataIndex: "endDate",
    key: "endDate",
    render: (date: string) => moment(date).format("DD/MM/YYYY")
  },
  {
    title: "Estimated Amount",
    dataIndex: "estimatedAmt",
    key: "estimatedAmt",
  },
  {
    title: "Expensed Amount",
    dataIndex: "expensedAmt",
    key: "expensedAmt",
  },
  {
    title: "Progress",
    dataIndex: "progress",
    key: "progress",
    render: (progress: string) => <Progress percent={30} size="small" />
  },
];

const ProjectsTable: FC<ProjectsTableProps> = (props) => {
  const { type, loading, projects, fetchProjects, pagination } = props;

  let columns = type == 'Proposed' ? columns1 : columns2;

  const history = useHistory();

  const searchProject = (search: string) => {
    fetchProjects(type, { search });
  }

  const redirectToProject = (project: Project) => ({
    onClick: () => {
      history.push(generatePath(appRoutes.PROJECT_DETAILS, {
        id: project.id
      }))
    },
  });

  const tableChangeHandler = (pagination: TablePaginationConfig) => {
    fetchProjects(type, {
      page: pagination.current,
    });

  }

  return (
    <div className="projects-table mt-5">
      <AppTable title={"Project"}
        handleSearch={searchProject}
        columns={columns}
        loading={loading}
        projectType={type}
        onChange={tableChangeHandler}
        totalRecords={pagination?.totalCount || 0}
        pagination={{
          pageSize: 50,
          current: pagination?.currentPage || 1,
          total: pagination?.totalCount || 0,
          showTotal: (total: number, range: [number, number]) => <p>Showing <b>{` ${range[0]} - ${range[1]}`}</b> of <b>{`${total.toLocaleString()}`}</b></p>
        }}
        data={projects}
        handleRedirect={redirectToProject}
      />
    </div>
  );
};

export default ProjectsTable;
