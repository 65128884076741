import React, {FC, useEffect, useState} from 'react'
import "./dashboardDonationStatus.scss"
import Card from "../../../shared/components/Card"
import {Col, Row, Select} from "antd";
import {Line} from "react-chartjs-2"
import moment from "moment";
import DashboardService from "../../../services/DashboardService/dashboard.service";

interface DashboardDonationStatusProps {

}

const DashboardDonationStatus: FC<DashboardDonationStatusProps> = (props) => {
    const { } = props;

    const indianAmountFormatter = (amount: number = 0) => new Intl.NumberFormat('en-IN').format(amount);

    const { fetchDonationStats, donationInfo, donationStatsLoading } = DashboardService();

    const [year, setYear] = useState<number>(parseInt(moment().format('YYYY')));
    const [cityId, setCityId] = useState<number>(0);
    const [chartData, setChartData] = useState<number[]>([]);

    const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    useEffect(()=>{
        fetchDonationStats(year).then()
    },[year])

    useEffect(()=>{
        if(donationInfo?.donationStats?.length)
            setChartData(labels.map(label => {
                const monthStat = donationInfo?.donationStats?.find(donationStat =>
                    label === moment(donationStat.month).format('MMM')
                )
                return monthStat ? monthStat.totalPayment : 0
            }))
        else
            setChartData([])
    },[donationInfo])

    const data = {
        labels: labels,
        datasets: [
            {
                label: '',
                data: chartData,
                fill: false,
                borderColor:'#773AF8',
                tension: 0.5
            },
        ],
    };

    const options = {
        elements:{
            point: {
                backgroundColor: '#FFFFFF',
                radius: 5,
            }
        },
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                backgroundColor: '#FFFFFF',
                borderColor:"#773AF8",
                borderWidth:0.5,
                bodyColor: '#7F91B0',
                titleColor: '#343C6A',
                padding: 14,
                callbacks:{
                    title: (context:any)=> context[0]?.label+' -  ₹'+indianAmountFormatter(context[0]?.raw),
                    beforeBody: (context:any)=> [
                        'Online                '+'₹'+indianAmountFormatter(
                            donationInfo?.donationStats.find(
                                    donationStat => context[0]?.label === moment(donationStat.month).format('MMM')
                            )?.onlinePayment
                        ),
                        'Offline                '+'₹'+indianAmountFormatter(
                            donationInfo?.donationStats.find(
                                donationStat => context[0]?.label === moment(donationStat.month).format('MMM')
                            )?.offlinePayment
                        ),
                        'One time            '+'₹'+indianAmountFormatter(
                            donationInfo?.donationStats.find(
                                donationStat => context[0]?.label === moment(donationStat.month).format('MMM')
                            )?.oneTimePayment
                        ),
                    ],
                    label: (context:any)=> [],

                }
            }
        },
        scales: {
            y: {
                beginAtZero: true,
                grid: {
                    drawBorder: false,
                },
                ticks: {
                    padding: 10,
                    maxTicksLimit: 4,
                },
            },
            xAxes: {
                grid:{
                    display: false
                }
            }
        },
    };

    return (
        <div className="dashboard-donation-status">
            <Card>
                <Row>
                    <Col span={13}>
                        <label className={"font-bold"}>Donations</label>
                        <Row>
                            <Col span={4}>
                                <div className={"mt-3"}>
                                    <Select key={'year'}
                                            options={[
                                                {label: 2021, value: 2021},
                                                {label: 2020, value: 2020},
                                            ]}
                                            value={year}
                                            onChange={(value)=>setYear(value)}
                                    />
                                </div>
                            </Col>
                            <Col span={8}>
                                <div className={"mt-3"}>
                                    <Select key={'district'}
                                            options={[
                                                {label: "All districts", value: 0},
                                                {label: "Chennai", value: 1},
                                                {label: "Coimbatore", value: 2},
                                            ]}
                                            value={cityId}
                                            onChange={(value:number)=>setCityId(value)}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={3}>
                        <div className={"sub-title"}>Total Donors</div>
                        <label className={"font-bold"}>{indianAmountFormatter(donationInfo?.totalDonors)}</label>
                    </Col>
                    <Col span={4}>
                        <div className={"sub-title"}>Current month donations</div>
                        <label className={"font-bold"}>{indianAmountFormatter(donationInfo?.currentMonthDonations)}</label>
                    </Col>
                    <Col span={4}>
                        <div className={"sub-title"}>Total Donations for the year</div>
                        <label className={"font-bold"}>{indianAmountFormatter(donationInfo?.totalDonationsCurrentYear)}</label>
                    </Col>
                </Row>
                <div className={"dashboard-donation-status__chart"}>
                    <Line data={data} options={options} />
                </div>
            </Card>
        </div>
    )
}

export default DashboardDonationStatus;