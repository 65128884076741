export const paymentOptions = [
    {
        label:"Online",
        value:"online"
    },
    {
        label:"Offline",
        value:"offline"
    }
]

export const paymentFilterOptions = [
    {
        label:'All',
        value:'all'
    },
    {
        label:'Regular',
        value:'regular'
    },
    {
        label:'One-time',
        value:'oneTime'
    },
]