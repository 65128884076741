import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { RootReducerProps } from "../reducers";
import * as ProjectFunctions from "../actions/project";

const mapStateToProps = (state: RootReducerProps) => ({
    projectStore: state.project.projectStore,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(ProjectFunctions, dispatch);

const ProjectContainer = (component: any) => {
    return connect(mapStateToProps, mapDispatchToProps)(component);
};

export default ProjectContainer;
