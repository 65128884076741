import React, {FC, useEffect, useState} from 'react'
import "./oneTimePayment.scss"
import PaymentExpanded from "../PaymentExpanded";
import AppTable from "../../../shared/components/AppTable";
import userPlaceholder from "../../../assets/userPlaceholder.png";
import AppHeader from "../../../shared/components/AppHeader";
import PaymentForm from "../PaymentForm";
import Back from "../../../shared/components/Back";
import PaymentService from "../../../services/PaymentService/payment.service";
import {PaymentModel} from "../../../models/Payment/payment.model";
import moment from "moment";

interface OneTimePaymentProps {

}

const OneTimePayment: FC<OneTimePaymentProps> = (props) => {
    const { } = props;

    const { fetchPaymentList, paymentList, paymentLoading } = PaymentService();

    const [showPaymentForm, setShowPaymentForm] = useState<boolean>(false);

    const handleSearch = () => {};

    const handleRowClick = () => {};

    useEffect(()=>{
        //fetchPaymentList(moment().format('YYYY'),moment().format('MMM'), true).then();
    },[])

    const handleMonthChange = (dateString: string) => {
       // fetchPaymentList(moment(dateString).format('YYYY'),moment(dateString).format('MMM'), true).then();
    }

    const columns = [
        {
            title: "S.NO",
            dataIndex: "id",
            key: "id",
            render: (text:string, record:PaymentModel) => paymentList.indexOf(record) + 1,
            //sorter: (a: any, b: any) => a.id - b.id,
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            render: (text: string, record: PaymentModel) =>
                <span className="text-capitalize profile-name-image">
                    <img src={record?.profilePicUrl ?? userPlaceholder} alt={""}/>{record?.donorName}
                </span>
        },
        {
            title: "Phone Number",
            dataIndex: "mobileNumber",
            key: "mobileNumber",
        },
        {
            title: "Address",
            dataIndex: "address",
            key: "address",
        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
            render: (text: string, record: PaymentModel) => <span>₹ {record?.amount}</span>
        }
    ];

    return (
        <div className="one-time-payment">
            <Back name="Back" />
            <div className="header">
                <AppHeader title={"One Time Payment"}
                           setFormVisible={setShowPaymentForm}
                />
            </div>

            <AppTable title={"Payments"}
                      handleSearch={handleSearch}
                      columns={columns}
                      loading={paymentLoading}
                      data={paymentList}
                      handleRedirect={handleRowClick}
                      expandable={{
                          expandedRowRender: (record:PaymentModel) => <PaymentExpanded payment={record}/>,
                          rowExpandable: (record:PaymentModel) => !!record.date,
                          expandIcon: ({ expanded , onExpand, record }:any) =>
                              <div className={`icon-accordion ${expanded ? 'animate' : ''}`}
                                   onClick={e => onExpand(record, e)}
                              />,
                          expandRowByClick: true
                      }}
                      showMonth={true}
                      handleMonthChange={handleMonthChange}
            />

            {/*<PaymentForm showModal={showPaymentForm}
                         setShowModal={setShowPaymentForm}
            />*/}

        </div>
    )
}

export default OneTimePayment;