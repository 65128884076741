import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import "./back.scss";

interface BackProps {
  name: string;
}

const Back: FC<BackProps> = (props) => {
  const history = useHistory();

  const { name } = props;

  const handleGoBack = () => {
    history.goBack();
  }

  return (
    <div className="back" onClick={handleGoBack}>
      <i className="icon-back"/>
      <span>{name}</span>
    </div>
  );
};

export default Back;
