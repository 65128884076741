import React, {FC} from 'react'
import "./dashboard.scss"
import AppHeader from "../../shared/components/AppHeader";
import DashboardDonationStatus from "./DashboardDonationStats";
import DashboardStats from "./DashboardStats";
import {Col, Row} from "antd";
import DashboardDonorChart from "./DashboardDonorChart";

interface DashboardProps {

}

const Dashboard: FC<DashboardProps> = (props) => {
    const { } = props;

    return (
        <div className="dashboard">
            <div className="header">
                <AppHeader title={"Dashboard"}
                           setFormVisible={()=>{}}
                           disableAdd={true}
                />
            </div>

            <div className="dashboard__content">
                <Row>
                    <Col span={12}>
                        <DashboardStats/>
                    </Col>
                    <Col span={11} offset={1}>
                        <DashboardDonorChart/>
                    </Col>
                </Row>
            </div>

            <div className="dashboard__content">
                <DashboardDonationStatus/>
            </div>
        </div>
    )
}

export default Dashboard;