import React, { FC, useEffect, useState } from "react";
import './listdonors.scss'
import DonorForm from "../DonorForm";
import DonorSuggestions from "../DonorSuggestions";
import AppTable from "../../../shared/components/AppTable";
import ListDonorsHeader from "./ListDonorsHeader";
import userPlaceholder from "../../../assets/userPlaceholder.png";
import { Dropdown, Menu, TablePaginationConfig } from "antd";
import moment from "moment";
import { generatePath } from "react-router-dom";
import { appRoutes } from "../../../routes/routeConstants/appRoutes";
import { useHistory } from "react-router";
import DonorService from "../../../services/DonorService/donor.service";
import { DonorModel } from "../../../models/Donor/donor.model";
import { DonorTypes } from "../../../enums/donorTypes";
import { DonorClassTypes } from "../../../enums/donorClassTypes";
import useFilters from "../../../shared/utils/useFilters";

interface ListDonorProps {
    listFor?: string;
    showMonth?: boolean;
    representativeId?: number;
}

const ListDonors: FC<ListDonorProps> = ({ listFor, showMonth, representativeId }) => {

    const history = useHistory();

    const { fetchDonorList, donorPagination, donorList, donorLoading } = DonorService();

    const {
        getParams,
        setParams,
    } = useFilters()

    const [createModalVisible, setCreateModalVisible] = useState(false);
    const [suggestionModalVisible, setSuggestionModalVisible] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([])

    useEffect(() => {
        fetchDonorList({
            representative_id: representativeId,
            page: donorPagination?.currentPage || getParams(['page'])?.page || 1,
            search: getParams(['search'])?.search || '',
        }).then()
    }, [])

    const [columns, setColumns] = useState([
        // {
        //     title: "S No",
        //     dataIndex: "id",
        //     key: "id",
        //     width: 70,
        //     //render: (text:string, record:DonorModel) => (donorList.indexOf(record) + 1),
        //     // render: (_: any, __: any, index: number) => index + 1,
        // },
        {
            title: "Donor ID",
            dataIndex: "regNumber",
            key: "regNumber",
            width: 70,
        },
        {
            title: "Name",
            dataIndex: "username",
            key: "username",
            width: 130,
            ellipsis: true,
        },
        {
            title: "Mobile",
            dataIndex: "mobileNumber",
            key: "mobileNumber",
            width: 150,
            render: (text: String, record: DonorModel) => <span>{`${record.isdCode || ''}-${record.mobileNumber || ''}`}</span>
        },
        {
            title: "Group #",
            dataIndex: "numberOfMembers",
            key: "numberOfMembers",
            width: 90,
            render: (text: string, record: DonorModel) =>
                <span>{record?.group?.members?.length}</span>
        },
        {
            title: "Type",
            dataIndex: "donorType",
            key: "donorType",
            width: 130,
            render: (text: string) => <span className="text-capitalize">{text}</span>
        },
        {
            title: "Group Head",
            dataIndex: "headRegNumber",
            key: "headRegNumber",
            width: 120,
        },
        {
            title: "Country",
            dataIndex: ["country", "name"],
            key: "country",
            width: 50,
            render: (text: string) => <span className="text-capitalize">{text}</span>
        },
        {
            title: "State",
            dataIndex: ["state", "name"],
            key: "state",
            width: 50,
            render: (text: string) => <span className="text-capitalize">{text}</span>
        },
        {
            title: "District",
            dataIndex: ["city", "name"],
            key: "city",
            width: 50,
            render: (text: string) => <span className="text-capitalize">{text}</span>
        },
        {
            title: "Pincode",
            dataIndex: "pinCode",
            key: "pinCode",
            width: 50,
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            width: 60,
            render: (text: string, record: DonorModel) =>
                <span className={`${record?.status ? 'text-active' : 'text-inactive'}`}>
                    {record?.status ? 'Active' : 'Inactive'}
                </span>
        },
    ]);

    useEffect(() => {
        if (listFor === 'representative') {
            columns.splice(4, 1);
            setColumns(
                [
                    ...columns.slice(0, 8),
                    {
                        title: "Donation",
                        dataIndex: "donation",
                        key: "donation",
                        width: 100,
                    },
                    ...columns.slice(8),
                    {
                        title: "Last pay",
                        dataIndex: "lastPay",
                        key: "lastPay",
                        width: 100,
                    },
                    // {
                    //     title: "",
                    //     dataIndex: "action",
                    //     key: "action",
                    //     width: 100,
                    //     render: (text: string, record: any) =>
                    //         <div className={"cursor-pointer"}>
                    //             <Dropdown overlay={menu}
                    //                 placement="bottomLeft"
                    //                 trigger={['click']}
                    //                 className='rep-donors-list__action'
                    //             >
                    //                 <i className="icon-more" />
                    //             </Dropdown>
                    //         </div>
                    // },
                ]
            )
        }
    }, [listFor])

    const closeFormHandler = (response?: boolean) => {
        setCreateModalVisible(false)
        if (response)
            fetchDonorList({
                representative_id: representativeId,
                page: donorPagination?.currentPage || getParams(['page'])?.page || 1,
                search: getParams(['search'])?.search || '',
            }).then();
    }

    const tableChangeHandler = (pagination: TablePaginationConfig) => {
        setParams({ ...getParams(['search']), page: pagination.current })
        fetchDonorList({
            representative_id: representativeId,
            page: pagination.current,
            limit: pagination.pageSize
        });

    }

    const menu = (
        <Menu>
            <Menu.Item key="1" onClick={() => { }}>
                <span className="change-rep-menu-item">
                    <i className={"icon-rep"} /> Change Representative
                </span>
            </Menu.Item>
        </Menu>
    );

    const handleRowClick = (donor: DonorModel) => ({
        onClick: () => {
            if (donor.type !== DonorClassTypes.CONTRIBUTOR)
                history.push(generatePath(appRoutes.DONOR_DETAILS, {
                    id: donor.id
                }))
        },
    });

    const handleSearch = (search: string) => {
        setParams({ search, page: 1 })
        fetchDonorList({ search, representative_id: representativeId, page: 1 })
    }

    return (
        <div className="list-donors">
            {!listFor && <ListDonorsHeader
                selectedDonorIds={selectedRowKeys}
                setSuggestionModalVisible={setSuggestionModalVisible}
                setCreateModalVisible={setCreateModalVisible}
                successHandler={() => setSelectedRowKeys([])}
            />}

            <AppTable title={"Donor"}
                columns={columns}
                scroll={{ x: true }}
                handleSearch={handleSearch}
                handleRedirect={handleRowClick}
                onChange={tableChangeHandler}
                data={donorList}
                loading={donorLoading}
                showMonth={showMonth}
                totalRecords={donorPagination?.totalCount || 0}
                pagination={{
                    pageSize: 50,
                    current: donorPagination?.currentPage || 1,
                    total: donorPagination?.totalCount,
                    showTotal: (total: number, range: [number, number]) => <p>Showing <b>{` ${range[0]} - ${range[1]}`}</b> of <b>{`${total.toLocaleString()}`}</b></p>
                }}
                rowKey={(donor: DonorModel) => donor.id as number}
                rowSelection={{
                    selectedRowKeys: selectedRowKeys as any,
                    onChange: setSelectedRowKeys as any
                }}
            />

            <DonorForm showModal={createModalVisible}
                setShowModal={closeFormHandler}
            />

            {/*<DonorSuggestions showModal={suggestionModalVisible}
                              setShowModal={setSuggestionModalVisible}
            />*/}

        </div>
    );
};

export default ListDonors;
