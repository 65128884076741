import React, { FC, useEffect, useState } from 'react'
import "./payments.scss"
import PaymentHeader from "./PaymentHeader";
import AppTable from "../../shared/components/AppTable";
import PaymentExpanded from "./PaymentExpanded";
import userPlaceholder from "../../assets/userPlaceholder.png";
import PaymentStats from "./PaymentStats";
import PaymentService from "../../services/PaymentService/payment.service";
import { PaymentModel } from "../../models/Payment/payment.model";
import moment from "moment";
import { paymentStatusMeta } from "../../shared/utils/paymentStatus";
import { Select, TablePaginationConfig } from "antd";
import { paymentFilterOptions } from '../../shared/utils/metaHelper';
import useFilters from '../../shared/utils/useFilters';

interface PaymentsProps {

}

const paymentParams = [
    "page",
    "search",
    "date",
    "filter"
]

const Payments: FC<PaymentsProps> = (props) => {
    const { } = props;

    const { fetchPaymentList, paymentPagination, paymentList, paymentLoading, paymentStats } = PaymentService();

    const [dateString, setDateString] = useState<string>(moment().format('MMM, YYYY'));
    const [filterValue, setFilterValue] = useState<string>('all');
    const [search, setSearch] = useState<string>();
    const [page, setPage] = useState<number>(1);

    useEffect(() => {
        refreshList()
    }, [])

    const refreshList = (date?: string, filter?: string, search?: string, pageNumber?: number) => {
        setFilterValue(filter ?? 'all');
        setDateString(moment(date).format('MMM, YYYY'));
        setSearch(search);
        fetchPaymentList({
            year: moment(date).format('YYYY'),
            month: moment(date).format('MMM'),
            search,
            page: pageNumber || page || 1,
            one_time_payment: filter === 'oneTime' ? true : filter === 'regular' ? false : null
        }).then();
    }

    const columns = [
        /*{
            title: "S.NO",
            dataIndex: "id",
            key: "id",
            render: (text:string, record:PaymentModel) => paymentList.indexOf(record) + 1,
            //sorter: (a: any, b: any) => moment(a.date) < moment(b.date) ? 1 : -1
        },*/
        {
            title: "Donor ID",
            dataIndex: ["donor", "regNumber"],
            key: "donor.regNumber",
            width: 100,
        },
        {
            title: "Name",
            dataIndex: ["donor", "username"],
            key: "donor.username",
            width: 150,
        },
        {
            title: "Role",
            dataIndex: "role",
            key: "role",
            width: 100,
            render: (text: string, record: PaymentModel) =>
                <span className="text-capitalize">{record?.donor ? record.donor.roleName : "Contributor"}</span>
        },
        {
            title: "Mobile",
            dataIndex: ["donor", "mobileNumber"],
            key: "donor.mobileNumber",
            width: 200,
        },
        {
            title: "District",
            dataIndex: ["city", "name"],
            key: "city.name",
            width: 100,
        },
        {
            title: "Pincode",
            dataIndex: "pinCode",
            key: "pinCode",
            width: 100,
        },
        {
            title: "Donation For",
            dataIndex: "donationFor",
            key: "donationFor",
            width: 130,
            render: (text: string, record: PaymentModel) => <span>
                1 {record?.paymentGroupMembers?.length ? '+ ' + record?.paymentGroupMembers?.length + ' M' : ''}
            </span>
        },
        {
            title: "Type",
            dataIndex: "type",
            key: "type",
            width: 100,
            render: (text: string, record: PaymentModel) =>
                <span>{record?.oneTimePayment ? 'One-time' : 'Regular'}</span>
        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
            width: 100,
            render: (text: string, record: PaymentModel) => <span>₹ {record?.amount}</span>
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            width: 100,
            render: (text: string, record: PaymentModel) =>
                <span className={`text-capitalize ${record.status}`}>
                    {record?.status ? paymentStatusMeta[record.status] : ''}
                </span>,
        },
    ];

    const handleSearch = (search: string) => {
        setPage(1)
        refreshList(dateString, filterValue, search, 1);
    }

    const handleMonthChange = (dateString: string) => {
        setPage(1)
        refreshList(dateString, filterValue, search, 1);
    }

    const handlePaymentFilter = (filterValue: string) => {
        setPage(1)
        refreshList(dateString, filterValue, search, 1);
    }

    const tableChangeHandler = (pagination: TablePaginationConfig) => {
        pagination.current && setPage(pagination.current)
        refreshList(dateString, filterValue, search, pagination.current || page || 1);
    }

    const paymentFilter = <Select
        options={paymentFilterOptions}
        defaultValue={filterValue}
        onSelect={(value) => handlePaymentFilter(value)}
    />

    return (
        <div className="payments">
            <PaymentHeader refreshList={refreshList} />

            <PaymentStats paymentStats={paymentStats} />

            <AppTable title={"Payments"}
                handleSearch={handleSearch}
                scroll={{ x: true }}
                columns={columns}
                loading={paymentLoading}
                data={paymentList}
                handleRedirect={() => { }}
                onChange={tableChangeHandler}
                totalRecords={paymentPagination?.totalCount || 0}
                pagination={{
                    pageSize: 15,
                    current: paymentPagination?.currentPage || 1,
                    total: paymentPagination?.totalCount || 0,
                    showTotal: (total: number, range: [number, number]) => <p>Showing <b>{` ${range[0]} - ${range[1]}`}</b> of <b>{`${total.toLocaleString()}`}</b></p>
                }}
                expandable={{
                    expandedRowRender: (record: PaymentModel) => <PaymentExpanded payment={record} />,
                    rowExpandable: (record: PaymentModel) => record.status !== "payment_initiated",
                    expandIcon: ({ expanded, onExpand, record }: any) => record.status !== "payment_initiated"
                        ? <div className={`icon-accordion ${expanded ? 'animate' : ''}`}
                            onClick={e => onExpand(record, e)}
                        />
                        : null,
                    expandRowByClick: true
                }}
                showMonth={true}
                handleMonthChange={handleMonthChange}
                paymentFilter={paymentFilter}
            />
        </div>
    )
}

export default Payments;