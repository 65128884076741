import React, { FC } from 'react'
import "./dashboardUserStats.scss"
import Card from "../../../../shared/components/Card"
import {Col, Row} from "antd";
import {UserStats} from "../../../../models/Dashboard/dashboardStats.model";

interface DashboardUserStatsProps {
    userStats: UserStats;
    title: string;
}

const DashboardUserStats: FC<DashboardUserStatsProps> = (props) => {
    const { title, userStats } = props;

    return (
        <div className="dashboard-user-stats">
            <Card>
                <Row>
                    <Col span={10}>
                        <div className={"sub-title"}>{title}</div>
                        <div className={"font-bold dashboard-user-stats__count"}>{userStats.totalDonors}</div>
                    </Col>
                    <Col span={7}>
                        <div className="active-stats">
                            <div className={"stats-label"}>Active</div>
                            <div className={"stats-value"}>{userStats.active}</div>
                        </div>
                    </Col>
                    <Col span={7}>
                        <div className="inactive-stats">
                            <div className={"stats-label"}>Inactive</div>
                            <div className={"stats-value"}>{userStats.inactive}</div>
                        </div>
                    </Col>
                </Row>
            </Card>
        </div>
    )
}

export default DashboardUserStats;