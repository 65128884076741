import { Button, Modal, Popover, Row, Tabs } from "antd";
import { Form, Formik, FormikValues } from "formik";
import React, { FC, useEffect, useState } from "react";
import { string } from "yup";
import { ProjectTypes } from "../../../enums/projectTypes";
import { Project } from "../../../models/Project/project.model";
import InputField from "../../../shared/components/InputField";
import ProjectsTable from "../ProjectsTable";
import "./listProjects.scss";
import {
  CloseOutlined,
  MoreOutlined
} from '@ant-design/icons';
import ProjectService from "../../../services/ProjectService/project.service";
import { createProjectValidation } from "./ProjectForm/creatProjectValidation";
import { GoogleApiWrapper } from "google-maps-react";
import ProjectForm from "./ProjectForm";
import ProjectSuggestions from "./ProjectSuggestions";
import projectService from "../../../services/ProjectService/project.service";
import AppHeader from "../../../shared/components/AppHeader";
import useFilters from "../../../shared/utils/useFilters";

const { TabPane } = Tabs;

const projectTabs = [
  {
    projectType: ProjectTypes.PROPOSED
  },
  {
    projectType: ProjectTypes.PLANNED
  },
  {
    projectType: ProjectTypes.ACTIVE
  },
  {
    projectType: ProjectTypes.COMPLETED
  },
  {
    projectType: ProjectTypes.SCRAPPED
  },
];

interface ListProjectsProps { }

const ListProjects: FC<ListProjectsProps> = () => {
  const { fetchSuggestedProjects, projectPagination, suggestedProjects, loading, projects, fetchProjects } = ProjectService()

  const [tab, setTab] = useState("1");

  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [suggestionModalVisible, setSuggestionModalVisible] = useState(false);

  const {
    setParams,
    getParams,
  } = useFilters()

  const refreshProjectList = (type: ProjectTypes, params?: any) => {
    setParams({ ...getParams(["search"]), ...params })
    fetchProjects(type, params)
  }

  useEffect(() => {
    // fetchSuggestedProjects().then();
  }, [])

  useEffect(() => {
    setParams({ page: 1 })
    fetchProjects(projectTabs[parseInt(tab) - 1].projectType, {
      page: 1,
      search: '',
    }).then()
  }, [tab])

  const handleChange = (activeKey: string) => {
    setTab(activeKey);
  };

  const Header = (
    <div className="header">
      <AppHeader title={'Project'} setFormVisible={setCreateModalVisible} />
      <span className="project-suggestion" onClick={() => setSuggestionModalVisible(true)}>
        <i className="icon-project" />
        <p>Project Suggestions <span>{suggestedProjects?.length}</span></p>
      </span>
    </div>
  );

  return (
    <div className="list-projects">
      {Header}
      <Tabs
        defaultActiveKey={tab}
        onChange={handleChange}
      >
        {projectTabs.map(({ projectType }, i) => (
          <TabPane tab={projectType}
            key={i + 1}
          >
            <ProjectsTable type={projectType}
              projects={projects}
              fetchProjects={refreshProjectList}
              loading={loading}
              pagination={projectPagination}
            />
          </TabPane>
        ))}
      </Tabs>

      <ProjectForm showModal={createModalVisible}
        setShowModal={setCreateModalVisible}
        refreshProjectList={refreshProjectList}
      />

      <ProjectSuggestions showModal={suggestionModalVisible}
        setShowModal={setSuggestionModalVisible}
      />

    </div>
  );
};

export default ListProjects;
