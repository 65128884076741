import React, { FC } from 'react'
import "./projectForm.scss"
import { Form, Formik, FormikValues } from "formik";
import { createProjectValidation } from "./creatProjectValidation";
import InputField from "../../../../shared/components/InputField";
import GoogleMaps, { GOOGLE_MAPS_URL } from "../../GoogleMap";
import { Button, Modal } from "antd";
import { Project } from "../../../../models/Project/project.model";
import ProjectService from "../../../../services/ProjectService/project.service";
import { ProjectTypes } from "../../../../enums/projectTypes";
import { debounce } from "throttle-debounce";
import PhoneNumberInput from '../../../../shared/components/PhoneNumberInput';

interface ProjectFormProps {
    showModal: boolean;
    setShowModal: Function;
    refreshProjectList: Function;
    project?: Project;
}

const ProjectForm: FC<ProjectFormProps> = ({ showModal, setShowModal, refreshProjectList, project }) => {

    const { createProject, loading } = ProjectService()

    const handleSubmit = (values: Project) => {
        values.status = ProjectTypes.PROPOSED.toLowerCase();
        createProject(values).then(() => {
            refreshProjectList(ProjectTypes.PROPOSED).then();
            setShowModal(false);
        })
    }
    let timeout: NodeJS.Timeout;
    return (
        <Modal title={
            <div className="modal-title">
                <h2>New Project</h2>
                <i className="icon-close" onClick={() => setShowModal(false)} />
            </div>
        }
            visible={showModal}
            onCancel={() => setShowModal(false)}
            className="create-project-modal"
        >
            <Formik
                initialValues={new Project()}
                onSubmit={handleSubmit}
                validationSchema={createProjectValidation}
            >
                {({ values, setFieldValue }) => {
                    return (
                        <Form className="create-project-form">
                            <InputField type="text" name="name" placeholder="Type name" title="Temple Name" />
                            <InputField type="text" name="templeNameTamil" placeholder="Type name" title="கோவில் பெயர்" />
                            <InputField type="text" name="inchargeName" placeholder="Type name" title="Temple Incharge Name" />
                            <InputField type="text" name="templeInchargeNameTamil" placeholder="Type name" title="கோவில் பொறுப்பாளர் பெயர்" />
                            {/* <InputField type="text" name="inchargeMobileNumber" placeholder="Type number" prefix="+91" title="Contact Number" /> */}
                            <PhoneNumberInput
                                title='Phone Number'
                                name={'inchargeMobileNumber'}
                                value={values.inchargeMobileNumber}
                                onChange={(value) => setFieldValue("inchargeMobileNumber", value)}
                            />
                            <InputField type="text" name="location" placeholder="Type location" title="Location" />
                            <InputField type="text" name="locationNameTamil" placeholder="Type name" title="இடம்" />
                            <div className="map">
                                <GoogleMaps
                                    loadingElement={<div style={{ height: `100%` }} />}
                                    containerElement={<div style={{ height: `100%` }} />}
                                    mapElement={<div style={{ height: `100%` }} />}
                                    googleMapURL={GOOGLE_MAPS_URL}
                                    coords={{
                                        latitude: values.lat || 13.0827,
                                        longitude: values.long || 80.2707,
                                    }}
                                    onLocationChange={(lat, long) => {
                                        timeout && clearTimeout(timeout)
                                        timeout = setTimeout(() => {
                                            setFieldValue("lat", lat)
                                            setFieldValue("long", long)
                                        }, 500);
                                    }}
                                />
                            </div>
                            <Button type="primary"
                                htmlType="submit"
                                loading={loading}
                            >
                                Create project
                            </Button>
                        </Form>
                    );
                }}
            </Formik>
        </Modal>
    )
}

export default ProjectForm;