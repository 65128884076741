import React, { FC, useEffect, useState } from 'react'
import "./donorAssignRep.scss"
import { Button, Col, Divider, Modal, Row } from "antd";
import userPlaceholder from "../../../../../assets/userPlaceholder.png";
import DonorRepDropdown from "../../../DonorRepDropdown";
import DonorService from '../../../../../services/DonorService/donor.service';

interface DonorAssignRepProps {
    showModal: boolean;
    donorIds?: number[]
    setShowModal: Function;
}

const DonorAssignRep: FC<DonorAssignRepProps> = (props) => {
    const { showModal, setShowModal, donorIds } = props;

    const [repId, setRepId] = useState<number>()

    const {
        bulkAssignRep,
        donorSubmitting,
    } = DonorService()

    useEffect(() => {
        setRepId(undefined)
    }, [showModal]);

    const bulkAssingHandler = async () => {
        if (!repId || !donorIds?.length) return
        await bulkAssignRep(repId, donorIds)
            && setShowModal(true)

    }

    return (
        <div className="donor-assign-rep">
            <Modal title={
                <div>
                    <div className="modal-title">
                        <h2>Assign area representative</h2>
                        <i className="icon-close" onClick={() => setShowModal(false)} />
                    </div>
                    <div className="sub-title">
                        Assign alternative representative for the below donors
                    </div>
                </div>
            }
                visible={showModal}
                onCancel={() => setShowModal(false)}
                className="create-project-modal donor-request-modal assign-rep-modal"
            >
                <div className={"donor-request-card"}>
                    {/* <div className={"donor-request-card__requester-info"}>
                        <div className={"requester-image"}>
                            <img src={userPlaceholder} alt={''}/>
                        </div>
                        <div className={"requester-details"}>
                            <h2 className={"font-semi-bold requester-name"}>
                                Nakahara Chuuya
                            </h2>
                            <div className={"requester-contact"}>
                                <Row>
                                    <Col span={9}>
                                        <i className={"icon-phone"}/> +91 9987723471
                                    </Col>
                                    <Col span={7}>
                                        <i className={"icon-reg-no-info"}/> APAK77134
                                    </Col>
                                    <Col span={7}>
                                        <i className={"icon-location"}/> Chennai
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div> */}
                    {/* <Divider className={"donor-request-card__divider"}/> */}
                    <div className={"donor-request-card__requester-info"}>
                        <div className={"sub-title donor-request-card__label"}>Representative</div>
                        <DonorRepDropdown value={repId} onChange={setRepId} />
                    </div>
                </div>
                <div>
                    <Button type="primary"
                        loading={donorSubmitting}
                        disabled={!repId}
                        onClick={bulkAssingHandler}
                    >
                        Proceed
                    </Button>
                </div>
            </Modal>
        </div>
    )
}

export default DonorAssignRep;