import React, { FC, useEffect, useState } from 'react'
import "./projectLocation.scss"
import { Form, Formik, FormikValues } from "formik";
import { Button } from "antd";
import InputField from "../../../../shared/components/InputField";
import GoogleMaps, { GOOGLE_MAPS_URL } from "../../GoogleMap";
import Card from "../../../../shared/components/Card";
import ProjectService from "../../../../services/ProjectService/project.service";
import { Project } from "../../../../models/Project/project.model";

interface ProjectLocationProps {
    project?: Project;
}

const ProjectLocation: FC<ProjectLocationProps> = ({ project }) => {
    const { loading, editProject } = ProjectService();

    const [locationEditable, setLocationEditable] = useState(false)
    const [initialValues, setInitialValues] = useState(
        {
            location: project?.location,
            locationNameTamil: project?.locationNameTamil,
            lat: project?.lat,
            long: project?.long,
        }
    )

    useEffect(() => {
        setInitialValues({
            location: project?.location,
            locationNameTamil: project?.locationNameTamil,
            lat: project?.lat,
            long: project?.long,
        })
    }, [project])

    let timeout: NodeJS.Timeout;
    return (
        <div className="project-location">
            <Card>
                <div className="location">
                    <Formik
                        initialValues={initialValues}
                        onSubmit={(values: FormikValues) => {
                            if (project?.id) editProject(project?.id, values).then()
                            setLocationEditable(!locationEditable)
                        }}
                        enableReinitialize
                    >
                        {({ values }) =>
                            <Form>
                                {locationEditable ?
                                    <div className="edit-controls float-right">
                                        <Button htmlType="submit">
                                            <i className="icon-done" />
                                        </Button>
                                        <Button onClick={() => setLocationEditable(!locationEditable)}>
                                            <i className="icon-close" />
                                        </Button>
                                    </div>
                                    :
                                    <i className="icon-edit float-right"
                                        onClick={() => setLocationEditable(!locationEditable)}
                                    />
                                }
                                <InputField
                                    className={"input-field " + (locationEditable ? "editable" : null)}
                                    placeholder="-"
                                    name="location"
                                    type="text"
                                />
                                <InputField
                                    className={"input-field " + (locationEditable ? "editable" : null)}
                                    placeholder="-"
                                    name="locationNameTamil"
                                    type="text"
                                />
                                <div className="mt-3 pl-0 pr-0 map">
                                    <GoogleMaps
                                        loadingElement={<div style={{ height: `100%` }} />}
                                        containerElement={<div style={{ height: `100%` }} />}
                                        mapElement={<div style={{ height: `100%` }} />}
                                        googleMapURL={GOOGLE_MAPS_URL}
                                        coords={{
                                            latitude: values?.lat ?? 0,
                                            longitude: values?.long ?? 0,
                                        }}
                                        onLocationChange={(lat, long) => {
                                            timeout && clearTimeout(timeout)
                                            timeout = setTimeout(() => {
                                                locationEditable &&
                                                    setInitialValues(prev => ({ ...prev, lat, long }))
                                            }, 500);
                                        }}
                                    />
                                </div>
                            </Form>}
                    </Formik>
                </div>
            </Card>
        </div>
    )
}

export default ProjectLocation;