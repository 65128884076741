import { PaginationModel } from './../../models/Pagination/pagination.model';
import { useState } from "react";
import { deserialize, serialize } from "serializr";
import { ProjectTypes } from "../../enums/projectTypes";
import axiosInstance from "../../interceptor/axiosInstance";
import { Project } from "../../models/Project/project.model";
import { SuggestedProject } from "../../models/Project/SuggestedProjects/SuggestedProject/project.model";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { store } from "../../store";
import { AUTHENTICATED } from "../../store/definitions/authConstants";
import { PROJECT_UPDATE } from "../../store/definitions/projectConstants";
import { ProjectAttachment } from "../../models/Project/ProjectAttachment/projectAttachment.model";
import { convertJSONToFormData } from "../../shared/utils/dataFormatConverter";
import { ProjectDocuments } from "../../models/Project/ProjectDocuments/projectDocuments.model";

const ProjectService = () => {
  const [project, setProject] = useState<Project>();

  const [projects, setProjects] = useState<Project[]>([]);

  const [projectPagination, setProjectPagination] = useState<PaginationModel>();

  const [suggestedProjects, setSuggestedProjects] = useState<SuggestedProject[]>([]);

  const [error, setError] = useState<Error | unknown>();

  const [loading, setLoading] = useState(false);

  const fetchProjects = async (type: ProjectTypes, params?: {
    search?: string,
    page?: number,
  }) => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(ApiRoutes.PROJECTS, {
        params: {
          status: type?.toLowerCase(),
          ...(params || {}),
          limit: 50
        },
      });
      const projects = deserialize(Project, data?.projects as any[]);
      const pagination = deserialize(PaginationModel, data)
      setProjects(projects);
      setProjectPagination(pagination)
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchProject = async (id: number) => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(ApiRoutes.PROJECTS + `/${id}`);
      const project = deserialize(Project, data?.project);
      setProject(project);
      store.dispatch({
        type: PROJECT_UPDATE,
        payload: { project },
      });
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }


  const createProject = async (project: Project) => {
    try {
      const _project = serialize(Project, project)
      setLoading(true);
      await axiosInstance.post(ApiRoutes.PROJECTS, _project);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);

    }
  };

  const deleteProject = async (id: number) => {
    try {
      setLoading(true);
      axiosInstance.delete(ApiRoutes.PROJECTS + `/${id}`).then(() => {
        Notification({
          message: "Project scrapped successfully",
          type: NotificationTypes.SUCCESS,
          description: ""
        })
      });
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  const editProject = async (id: number, data: Project) => {
    try {
      const _project = serialize(Project, data)
      setLoading(true);
      const response = await axiosInstance.put(ApiRoutes.PROJECTS + `/${id}`, _project);
      const project = deserialize(Project, response.data["project"])
      Notification({
        message: "Project updated successfully",
        type: NotificationTypes.SUCCESS,
        description: ""
      })
      return project
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  const createProjectAttachment = async (data: Project) => {
    try {
      const _project = serialize(Project, data)
      const formData = convertJSONToFormData(_project);
      setLoading(true);
      return axiosInstance.post(ApiRoutes.PROJECT_ATTACHMENTS, formData).then((response) => {
        return deserialize(ProjectAttachment, response.data['project_attachment'])
      })
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteProjectAttachment = async (attachmentId: string) => {
    try {
      setLoading(true);
      return axiosInstance.delete(ApiRoutes.PROJECT_ATTACHMENTS + `/${attachmentId}`)
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const createProjectDocument = async (data: Project) => {
    try {
      const _project = serialize(Project, data)
      const formData = convertJSONToFormData(_project);
      setLoading(true);
      return axiosInstance.post(ApiRoutes.PROJECT_DOCUMENTS, formData).then((response) => {
        return deserialize(ProjectDocuments, response.data['project_document'])
      })
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteProjectDocument = async (documentId: string) => {
    try {
      setLoading(true);
      return axiosInstance.delete(ApiRoutes.PROJECT_DOCUMENTS + `/${documentId}`).then(() => documentId)
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSuggestedProjects = async () => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(ApiRoutes.PROJECT_SUGGESTIONS);
      const suggestedprojects = deserialize(SuggestedProject, data?.projects as any[]);
      setSuggestedProjects(suggestedprojects);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  const updateSuggestedProject = async (id: string, status: string) => {
    try {
      setLoading(true);
      const updateOptions = {
        "project": {
          "id": id,
          "status": status
        }
      }
      await axiosInstance.put(ApiRoutes.PROJECT_SUGGESTIONS + `/${id}`, updateOptions);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  return {
    projects,
    project,
    error,
    loading,
    fetchProjects,
    fetchProject,
    fetchSuggestedProjects,
    suggestedProjects,
    updateSuggestedProject,
    projectPagination,
    createProject,
    deleteProject,
    editProject,
    createProjectAttachment,
    deleteProjectAttachment,
    createProjectDocument,
    deleteProjectDocument
  };
};

export default ProjectService;
