import React, {FC, useEffect, useState} from 'react'
import "./projectStats.scss"
import {Col, Row} from "antd";
import templePlaceholder from "../../../../assets/dashboardTemple.png";
import {ProjectStatsModel} from "../../../../models/Dashboard/dashboardStats.model";

interface ProjectStatsProps {
    projectStats:ProjectStatsModel;
}

const ProjectStats: FC<ProjectStatsProps> = ({projectStats}) => {

    const [total, setTotal] = useState<number>(0)

    useEffect(()=>{
        setTotal(
            Object.values(projectStats).reduce(
                (accumulator, curr) => accumulator + curr
            )
        )
    },[projectStats])

    return (
        <div className="project-stats">
            <div className="project-stats__total">
                <Row>
                    <Col span={12}>
                        <div>Total Projects</div>
                        <h1 className="font-bold project-stats__count">{total}</h1>
                    </Col>
                    <Col span={12}>
                        <img src={templePlaceholder} alt={''} className={"project-stats__image"}/>
                    </Col>
                </Row>
            </div>
            <div className="project-stats__split">
                <Row>
                    {Object.entries(projectStats).map((projectStat, index:number) =>
                        <Col span={4} offset={ index > 0 ? 1 : 0 }>
                            <div className={"sub-title text-capitalize"}>{projectStat[0]}</div>
                            <h3 className={"font-bold"}>{projectStat[1]}</h3>
                        </Col>
                    )}
                </Row>
            </div>
        </div>
    )
}

export default ProjectStats;