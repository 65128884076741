import { Col, Divider, Menu, Row } from "antd";
import React, { FC, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { sidemenu } from "./data";
import "./sideMenu.scss";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthState } from "../../../store/reducers/authReducer";
import UserService from "../../../services/AuthService/auth.service";
import ContactDetails from "../../../views/ContactDetails";

interface SideMenuProps extends AuthState { }

const SideMenu: FC<SideMenuProps> = (props) => {
  const { authenticated, user } = props;

  const history = useHistory();

  const { logoutUser } = UserService();

  const [showInfo, setShowInfo] = useState(false);

  const [pathname, setPathname] = useState<string>(history.location.pathname);
  const [showContactUpdate, setShowContactUpdate] = useState<boolean>(false);

  useEffect(() => {
    return history.listen((location) => {
      setPathname(location?.pathname);
    });
  }, [history]);

  const handleClick = (selected: any) => {
    setPathname(selected?.key);
  };

  const UserInfo = (
    <div className={`user-info ${showInfo ? "fade-in" : "fade-out"}`}>
      <Row>
        <Col span={4} className="user-wrapper">
          <i className="icon-my-account" />
        </Col>
        <Col span={2} />
        <Col span={18}>
          <p className="name">{user?.username}</p>
        </Col>
      </Row>
      <Divider />
      <Row className="mb-5">
        <Col span={4} className="user-wrapper">
          <i className="icon-change-password" />
        </Col>
        <Col span={2} />
        <Col span={18}>
          <p className="name">Change Password</p>
        </Col>
      </Row>
      <Row onClick={logoutUser} className="user-menu">
        <Col span={4} className="user-wrapper">
          <i className="icon-logout" />
        </Col>
        <Col span={2} />
        <Col span={18}>
          <p className="name">Logout</p>
        </Col>
      </Row>
    </div>
  );

  const handleShowInfo = () => {
    setShowInfo(show => !show);
  }

  const handleRedirect = (path: string) => {
    history.push(path);
  }

  return (
    <div className="sidemenu__container">
      <ContactDetails showModal={showContactUpdate}
        setShowModal={setShowContactUpdate}
      />
      {!history?.location?.pathname?.includes('auth') && authenticated ?
        <Menu
          onClick={handleClick}
          style={{ width: "100%" }}
          mode="inline"
          className="side-menu"
        >
          <Menu className="top-side-menu"
            defaultSelectedKeys={["/projects"]}
            selectedKeys={[pathname]}
          >
            <Menu.Item key="0" className="heading" disabled>
              <h1>Aran Pani</h1>
            </Menu.Item>
            {sidemenu.map(({ label, icon, path, disable }, index) =>
              <Menu.Item
                disabled={disable}
                className={`${history.location.pathname.includes(path) ? "ant-menu-item-selected" : ""} ${disable && 'disable'}`}
                key={path} onClick={() => handleRedirect(path)}>
                <i className={icon} />
                <span>{label}</span>
              </Menu.Item>
            )}
            <li onClick={() => setShowContactUpdate(true)}
              className={"ant-menu-item"}
              style={{ paddingLeft: '24px' }}
            >
              <i className="icon-contact" />
              <span>Contact Details</span>
            </li>
          </Menu>
          <Menu className="bottom-side-menu">
            {showInfo && UserInfo}
            <Row className="user-card cursor-pointer" onClick={handleShowInfo}>
              <Col span={4} className="user-wrapper">
                <i className="icon-profile-placeholder" />
              </Col>
              <Col span={2} />
              <Col span={16}>
                <p className="name">{user?.username}</p>
                <p className="role">Admin</p>
              </Col>
              <Col span={2} className={`${showInfo ? "deg-180" : "deg-0"}`}>
                <i className="icon-down" />
              </Col>
            </Row>
          </Menu>
        </Menu> : <></>
      }
    </div>
  );
};

export default AuthContainer(SideMenu);