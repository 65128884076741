import React, { FC, useEffect, useRef, useState } from 'react'
import "./paymentForm.scss"
import { Form, Formik } from "formik";
import InputField from "../../../shared/components/InputField";
import { Button, Modal } from "antd";
import { createPaymentValidation, otpValidation } from './createPaymentValidation';
import TextareaField from "../../../shared/components/TextareaField";
import DropdownField from "../../../shared/components/DropdownField";
import OtpField from "../../../shared/components/OtpField";
import PaymentService from "../../../services/PaymentService/payment.service";
import { PaymentModel } from "../../../models/Payment/payment.model";
import { paymentOptions } from "../../../shared/utils/metaHelper";
import { DonorModel } from "../../../models/Donor/donor.model";
import { ApiRoutes } from '../../../routes/routeConstants/apiRoutes';
import { GenderModel, OptionModel } from '../../../models/Meta/meta.model';
import MetaService from '../../../services/MetaService/meta.service';
import PhoneNumberInput from '../../../shared/components/PhoneNumberInput';
import { parsePhoneNumber } from 'react-phone-number-input';
import useFilters from '../../../shared/utils/useFilters';

interface PaymentFormProps {
    showModal: boolean;
    setShowModal: Function;
    refreshList: Function;
}

const PaymentForm: FC<PaymentFormProps> = (props) => {
    const { showModal, setShowModal, refreshList } = props;

    const {
        fetchMeta,
    } = MetaService()

    const {
        getParams,
    } = useFilters()

    const otpFormRef = useRef<any>();
    const paymentFormRef = useRef<any>();

    const { createOneTimePayment, paymentLoading, triggerPaymentOtp,
        searchPaymentDonor, donor, donorLoading } = PaymentService();

    const [cities, setCities] = useState<OptionModel[]>([])

    const [states, setStates] = useState<OptionModel[]>([])

    const [countries, setCountries] = useState<OptionModel[]>([])

    const [genders, setGenders] = useState<OptionModel[]>([])

    const [ids, setIds] = useState<OptionModel[]>([])

    const [paymentInitialValues, setPaymentInitialValues] = useState<PaymentModel>(new PaymentModel())

    const [showOtpForm, setShowOtpForm] = useState<boolean>(false);
    const [otpId, setOtpId] = useState<number>();
    const [paymentValues, setPaymentValues] = useState<PaymentModel>();

    useEffect(() => {
        fetchMeta(ApiRoutes.GENDERS, setGenders, "genders", {}, GenderModel)
        fetchMeta(ApiRoutes.META_COUNTRIES, setCountries, "countries")
        fetchMeta(ApiRoutes.IDENTIFICATION_CARDS, setIds, "identification_cards")
    }, [])

    useEffect(() => {
        !showModal && handleReset()
    }, [showModal])

    useEffect(() => {
        setDonorValues(donor || new DonorModel())
    }, [donor])


    const handlePaymentSubmit = (values: PaymentModel) => {
        values.isdCode = "+" + parsePhoneNumber(values.mobileNumber || '')?.countryCallingCode as string
        values.mobileNumber = parsePhoneNumber(values.mobileNumber || '')?.nationalNumber as string
        triggerPaymentOtp().then((res) => {
            setOtpId(res?.id)
            setPaymentValues(values);
            setShowOtpForm(true);
        })
    }

    const handleOtpSubmit = (values: PaymentModel) => {
        createOneTimePayment({
            ...paymentValues,
            ...values,
            otpId,
        }).then((res) => {
            if (!res) return
            handleReset()
            setShowModal(false)
            refreshList(
                getParams(['date']),
                getParams(['filter']),
                getParams(['search']),
                getParams(['page']),
            )
        });
    }

    const handleReset = () => {
        setPaymentValues(new PaymentModel());
        setShowOtpForm(false);
        paymentFormRef?.current?.resetForm();
        otpFormRef?.current?.resetForm();
    }

    let counter: NodeJS.Timeout;
    const handleMobileNumberChange = (value: string, setFieldValue: Function) => {
        if (counter)
            clearTimeout(counter)
        counter = setTimeout(async () => {
            const donorsWithSameMobile = await searchPaymentDonor({
                mobile_number: parsePhoneNumber(value || '')?.nationalNumber,
                isd_code: "+" + parsePhoneNumber(value || '')?.countryCallingCode
            })
            const donorWithSameIsd = donorsWithSameMobile?.find(donor => donor.isdCode === ("+" + parsePhoneNumber(value || '')?.countryCallingCode))
            setDonorValues(donorWithSameIsd)
        }, 800);
    }

    const setDonorValues = (donor?: DonorModel) => {
        paymentFormRef?.current?.setFieldValue("donorId", donor?.id)
        paymentFormRef?.current?.setFieldValue("donorName", donor?.username)
        paymentFormRef?.current?.setFieldValue("countryId", donor?.country?.id)
        paymentFormRef?.current?.setFieldValue("countryName", donor?.country?.name)
        paymentFormRef?.current?.setFieldValue("cityId", donor?.city?.id)
        paymentFormRef?.current?.setFieldValue("stateId", donor?.state?.id)
        paymentFormRef?.current?.setFieldValue("age", donor?.age)
        paymentFormRef?.current?.setFieldValue("pinCode", donor?.pinCode)
        paymentFormRef?.current?.setFieldValue("genderId", donor?.gender?.id)
        paymentFormRef?.current?.setFieldValue("identificationCardValue", donor?.identificationCardValue)
        paymentFormRef?.current?.setFieldValue("identificationCardId", donor?.identificationCard?.id)
        paymentFormRef?.current?.setFieldValue("pan", donor?.pan)
        if (donor?.countryId || donor?.country?.id)
            fetchMeta(ApiRoutes.META_STATES, setStates, "states", { country_id: donor?.countryId || donor?.country?.id })
        if (donor?.stateId || donor?.state?.id)
            fetchMeta(ApiRoutes.META_CITIES, setCities, "cities", { state_id: donor?.stateId || donor?.state?.id })
    }

    return (
        <div className="payment-form">
            <Modal title={
                <div className="modal-title">
                    <h2>New One Time Payment</h2>
                    <i className="icon-close" onClick={() => setShowModal(false)} />
                </div>
            }
                visible={showModal}
                onCancel={() => setShowModal(false)}
                className="create-project-modal create-payment-modal"
            >
                {showOtpForm ?
                    <Formik
                        initialValues={{ otp: "" }}
                        onSubmit={handleOtpSubmit}
                        validationSchema={otpValidation}
                        enableReinitialize
                        innerRef={otpFormRef}
                    >
                        {({ setFieldValue, isValid }) => {
                            return (
                                <Form className="create-project-form">
                                    <div className="form-field">
                                        <OtpField name="otp"
                                            title="Enter OTP sent to Head Trustee +91 ******00"
                                            numInputs={4}
                                            onChange={(value: any) => {
                                                setFieldValue("otp", value)
                                            }}
                                        />
                                    </div>
                                    <div className="form-field">
                                        <Button type="primary"
                                            htmlType="submit"
                                            loading={paymentLoading}
                                            disabled={!isValid}
                                        >
                                            Confirm Payment
                                        </Button>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                    :
                    <Formik
                        initialValues={paymentInitialValues}
                        onSubmit={handlePaymentSubmit}
                        validationSchema={createPaymentValidation}
                        enableReinitialize
                        innerRef={paymentFormRef}
                    >
                        {({ values, setFieldValue, isValid, errors }) => {
                            return (
                                <Form className="create-project-form">
                                    <div className="form-field">
                                        {/* <InputField type="text"
                                            name="mobileNumber"
                                            placeholder="Type number"
                                            title="Phone Number"
                                            prefix={"+91"}
                                            onChange={(event: any) => {
                                                handleMobileNumberChange(event, setFieldValue)
                                            }}
                                        /> */}
                                        <PhoneNumberInput
                                            title='Phone Number'
                                            name={'mobileNumber'}
                                            value={values.mobileNumber}
                                            onChange={(value) => {
                                                setFieldValue("mobileNumber", value)
                                                if (value?.length)
                                                    handleMobileNumberChange(value || '', setFieldValue)
                                            }}
                                        />
                                        {donorLoading && <div className="label">Searching donor...</div>}
                                    </div>
                                    <div className="form-field">
                                        <InputField type="email"
                                            name="email"
                                            placeholder="Type email"
                                            title="Email Id"
                                        />
                                    </div>
                                    <div className="form-field">
                                        <InputField type="text"
                                            name="donorName"
                                            placeholder="Type donor name"
                                            title="Donor Name"
                                        />
                                    </div>
                                    <div className="form-field">
                                        <DropdownField name="country"
                                            options={countries}
                                            placeHolder="Select Country"
                                            showArrow={true}
                                            value={values?.countryId || values.country?.id}
                                            className="statusId__dropdown"
                                            onChange={(value, option) => {
                                                setFieldValue("countryId", value)
                                                setFieldValue("countryName", option.label)
                                                fetchMeta(ApiRoutes.META_STATES, setStates, "states", { country_id: value })
                                            }}
                                            title="Country"
                                        />
                                    </div>
                                    {values.countryName
                                        && values.countryName?.toLowerCase()?.includes('india')
                                        && <div className="form-field">
                                            <DropdownField name="state"
                                                options={states}
                                                placeHolder="Select state"
                                                showArrow={true}
                                                disabled={!values.country?.id && !values.countryId}
                                                value={values?.stateId || values.state?.id}
                                                className="statusId__dropdown"
                                                onChange={(value) => {
                                                    setFieldValue("stateId", value)
                                                    fetchMeta(ApiRoutes.META_CITIES, setCities, "cities", { state_id: value })
                                                }}
                                                title="State"
                                            />
                                        </div>}
                                    {values.countryName
                                        && values.countryName?.toLowerCase()?.includes('india')
                                        && <div className="form-field">
                                            <DropdownField name="city"
                                                options={cities}
                                                placeHolder="Select city"
                                                showArrow={true}
                                                disabled={!values.state?.id && !values.stateId}
                                                value={values?.cityId || values.city?.id}
                                                className="statusId__dropdown"
                                                onChange={(value) => { setFieldValue("cityId", value) }}
                                                title="District"
                                            />
                                        </div>}
                                    <div className="form-field">
                                        <OtpField name="pinCode"
                                            title="Pincode"
                                            numInputs={6}
                                            value={values?.pinCode}
                                            onChange={(value: any) => {
                                                setFieldValue("pinCode", value)
                                            }}
                                        />
                                    </div>
                                    <div className="form-field">
                                        <DropdownField name="paymentMode"
                                            options={paymentOptions}
                                            placeHolder="Select Payment mode"
                                            showArrow={true}
                                            value={values?.paymentMode}
                                            className="statusId__dropdown"
                                            onChange={(value) => {
                                                setFieldValue("paymentMode", value)
                                            }}
                                            title="Payment Mode"
                                        />
                                    </div>
                                    <div className="form-field">
                                        <InputField type="text"
                                            name="transactionId"
                                            placeholder="Type Transaction ID"
                                            title="Transaction ID"
                                        />
                                    </div>
                                    <div className="form-field">
                                        <InputField type="text"
                                            name="pan"
                                            placeholder="Type PAN number"
                                            title="PAN Number"
                                        />
                                    </div>
                                    <div className="form-field">
                                        <DropdownField name="identificationCardId"
                                            options={ids}
                                            placeHolder="Select ID Card"
                                            showArrow={true}
                                            value={values?.identificationCardId || values.identificationCard?.id}
                                            className="statusId__dropdown"
                                            onChange={(value) => {
                                                setFieldValue("identificationCardId", value)
                                            }}
                                            title="ID Card"
                                        />
                                    </div>
                                    <div className="form-field">
                                        <InputField type="text"
                                            name="identificationCardValue"
                                            placeholder="Type ID Card number"
                                            title="ID Card Number"
                                        />
                                    </div>
                                    <div className="form-field">
                                        <DropdownField name="genderId"
                                            options={genders}
                                            placeHolder="Select Gender"
                                            showArrow={true}
                                            value={values?.genderId || values.gender?.id}
                                            className="statusId__dropdown"
                                            onChange={(value) => {
                                                setFieldValue("genderId", value)
                                            }}
                                            title="Gender"
                                        />
                                    </div>
                                    <div className="form-field">
                                        <InputField type="number"
                                            name="age"
                                            placeholder="Type Age"
                                            title="Age"
                                        />
                                    </div>
                                    <div className="form-field">
                                        <InputField type="number"
                                            name="amount"
                                            placeholder="0"
                                            title="Amount"
                                            prefix="₹"
                                        />
                                    </div>
                                    <div className="form-field">
                                        <Button type="primary"
                                            htmlType="submit"
                                            loading={paymentLoading}
                                            disabled={!isValid}
                                        >
                                            Send OTP
                                        </Button>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                }
            </Modal>
        </div>
    )
}

export default PaymentForm;