import React, { useEffect, memo, useRef, useState } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
interface CoordsFunc {
  lat: number | Function;
  lng: number | Function;
}

export interface Coords {
  latitude: number;
  longitude: number;
}
interface GoogleMapProps {
  coords: Coords;
  onLocationChange: (lat: number, lng: number) => void;
}

export const GOOGLE_MAPS_URL = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API}&v=3.exp&libraries=geometry,drawing,places`;


const GoogleMaps = withScriptjs(
  withGoogleMap((props: GoogleMapProps) => {
    const { coords, onLocationChange } = props;

    const [center, setCenter] = useState<CoordsFunc | any>({
      lat: coords?.latitude ?? 13.0827,
      lng: coords?.longitude ?? 80.2707,
    });
    const refMap: any = useRef(null);

    useEffect(() => {
      setCenter({ lat: coords?.latitude, lng: coords?.longitude });
    }, [coords]);

    const updateCurrentCoords = () => {
      onLocationChange(center?.lat(), center?.lng());
    };

    const handleBoundsChanged = () => {
      const mapCenter = refMap?.current?.getCenter();
      setCenter(mapCenter);
      updateCurrentCoords();
    };

    return (
      <GoogleMap
        ref={refMap}
        defaultZoom={10}
        center={{ lat: coords?.latitude, lng: coords?.longitude }}
        onCenterChanged={handleBoundsChanged}
      >
        <Marker position={center}/>
      </GoogleMap>
    );
  })
);

export default memo(GoogleMaps);
