import { appRoutes } from "../../../routes/routeConstants/appRoutes";

export const sidemenu = [
  {
    label: "Dashboard",
    icon: "icon-dashboar",
    path: appRoutes.DASHBOARD,
    disable: false,
  },
  {
    label: "Projects",
    icon: "icon-project",
    path: appRoutes.PROJECTS,
    disable: false,
  },
  {
    label: "Donors",
    icon: "icon-donor",
    path: appRoutes.DONORS,
    disable: false,
  },
  {
    label: "Representative",
    icon: "icon-rep",
    path: appRoutes.REPRESENTATIVE,
    disable: false,
  },
  {
    label: "Payment",
    icon: "icon-payment",
    path: appRoutes.PAYMENT,
    disable: false,
  },
  {
    label: "Internal users",
    icon: "icon-internal-users",
    path: appRoutes.INTERNAL_USERS,
    disable: false,
  },
  {
    label: "Subscription",
    icon: "icon-subscription",
    path: appRoutes.SUBSCRIPTION,
    disable: false,
  }
];
