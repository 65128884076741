import { NotificationTypes } from './../../enums/notificationTypes';
import { PaginationModel } from './../../models/Pagination/pagination.model';
import axiosInstance from "../../interceptor/axiosInstance";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { deserialize, serialize } from "serializr";
import { useState } from "react";
import { PaymentModel, PaymentStatsModel } from "../../models/Payment/payment.model";
import { DonorModel } from "../../models/Donor/donor.model";
import Notification from '../../shared/components/Notification';
import { generatePath } from 'react-router-dom';

const PaymentService = () => {

    const [paymentLoading, setPaymentLoading] = useState<boolean>(false);
    const [paymentPagination, setPaymentPagination] = useState<PaginationModel>();
    const [paymentList, setPaymentList] = useState<PaymentModel[]>([]);
    const [paymentStats, setPaymentStats] = useState<PaymentStatsModel>(new PaymentStatsModel());
    const [donor, setDonor] = useState<DonorModel>();
    const [donorLoading, setDonorLoading] = useState<boolean>(false);
    const [isReceiptLoading, setIsReceiptLoading] = useState(false);

    const fetchPaymentList = async (params: any = {}) => {
        try {
            params.limit = 15
            setPaymentLoading(true);
            const { data } = await axiosInstance.get(ApiRoutes.PAYMENTS, { params: params });
            const payments: any = deserialize(PaymentModel, data['payments']);
            setPaymentList(payments);
            const paymentStats = deserialize(PaymentStatsModel, data);
            setPaymentStats(paymentStats);
            setPaymentPagination(deserialize(PaginationModel, data))
        } catch (error) {
            console.log("payment list", error)
        } finally {
            setPaymentLoading(false);
        }
    };

    const createOneTimePayment = async (payment: PaymentModel) => {
        try {
            setPaymentLoading(true);
            const payload = {
                payment: serialize(PaymentModel, payment)
            }
            const { data } = await axiosInstance.post(ApiRoutes.PAYMENTS, payload);
            return data;
        } catch (error) {
            console.log("payment create", error)
        } finally {
            setPaymentLoading(false);
        }
    };

    const triggerPaymentOtp = async () => {
        setPaymentLoading(true)
        try {
            setPaymentLoading(true);
            const { data } = await axiosInstance.post(ApiRoutes.PAYMENT_OTP);
            return data['admin_otp'];
        } catch (error) {
            console.log("payment create", error)
        } finally {
            setPaymentLoading(false);
        }
    };

    const searchPaymentDonor = async (params: {} = {}) => {
        try {
            setDonorLoading(true);
            const { data } = await axiosInstance.get(ApiRoutes.DONOR_SEARCH, { params: params });
            const donors = deserialize(DonorModel, data['donors']) as DonorModel[]
            return donors
        } catch (error) {
            console.log("donor search", error)
        } finally {
            setDonorLoading(false);
        }
    };

    const generatePaymentReport = async (params: {
        year: string
    }) => {
        try {
            setPaymentLoading(true)
            const response = await axiosInstance.get(ApiRoutes.PAYMENT_REPORTS, { params })
            Notification({
                message: "",
                description: "You will receive the requested payment data in the configured email shortly",
                type: NotificationTypes.SUCCESS
            })
            return response.data
        } catch (error) {

        } finally {
            setPaymentLoading(false)
        }
    }

    const fetchPayment = async (id: number) => {
        try {
            setIsReceiptLoading(true);
            const ENDPOINT = generatePath(ApiRoutes.PAYMENT, {
                id
            })
            const { data } = await axiosInstance.get(ENDPOINT);
            const payment = deserialize(PaymentModel, data["payment"]);
            return payment;
        } catch (ex) {
            return false;
        }finally{
            setIsReceiptLoading(false);
        }
    };

    return {
        fetchPayment,
        fetchPaymentList,
        paymentLoading,
        paymentList,
        paymentStats,
        createOneTimePayment,
        triggerPaymentOtp,
        searchPaymentDonor,
        donor,
        paymentPagination,
        donorLoading,
        generatePaymentReport,
        isReceiptLoading
    }
}

export default PaymentService;