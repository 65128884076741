import React, { FC } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import RegisterForm from "../../views/Auth/RegisterForm";
import LoginForm from "../../views/Auth/LoginForm";
import { RouterProps } from "../../shared/types/route.type";
import { appRoutes } from "../../routes/routeConstants/appRoutes";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";

const authRouter = () => {
  const routes: RouterProps[] = [
    { exact: true, path: appRoutes.REGISTER, component: RegisterForm },
    { exact: true, path: appRoutes.LOGIN, component: LoginForm },
    { exact: true, path: appRoutes.FORGOT_PASSWORD, component: ForgotPassword },
    { exact: true, path: appRoutes.RESET_PASSWORD, component: ResetPassword },
  ];

  return (
    <Switch>
      {routes.map(({ component, ...routerProps }) => (
        <Route {...routerProps} component={component as FC} />
      ))}
      <Redirect from={appRoutes.AUTH} to={appRoutes.LOGIN} />
    </Switch>
  );
};

export default authRouter;
