import React, {FC, useEffect} from 'react'
import "./dashboardStats.scss"
import ProjectStats from "./DashboardProjectStats";
import DashboardUserStats from "./DashboardUserStats";
import DashboardService from "../../../services/DashboardService/dashboard.service";

interface DashboardStatsProps {

}

const DashboardStats: FC<DashboardStatsProps> = () => {

    const { fetchDashboardStats, dashboardStats, dashboardLoading } = DashboardService();

    useEffect(()=>{
        fetchDashboardStats().then()
    },[])

    return (
        <div className="dashboard-stats">
            <ProjectStats projectStats={dashboardStats?.projectStats}/>
            <div className={"dashboard__user-stats"}>
                <div className={"dashboard__user-stats__column"}>
                    <DashboardUserStats userStats={dashboardStats?.areaRepStats}
                                        title={'Area Reps'}
                    />
                </div>
                <div className={"dashboard__user-stats__column"}>
                    <DashboardUserStats userStats={dashboardStats?.internalUsersStats}
                                        title={'Internal Users'}
                    />
                </div>
            </div>
        </div>
    )
}

export default DashboardStats;