import React, { FC, useRef, useState } from 'react'
import "./paymentHeader.scss"
import AppHeader from "../../../shared/components/AppHeader";
import { useHistory } from "react-router";
import { appRoutes } from "../../../routes/routeConstants/appRoutes";
import PaymentForm from "../PaymentForm";
import { Button, Col, Dropdown, Form, Row } from 'antd';
import { Formik } from 'formik';
import DropdownField from '../../../shared/components/DropdownField';
import PaymentService from '../../../services/PaymentService/payment.service';

interface PaymentHeaderProps {
    refreshList: Function;
}

const paymentYearOptions = [
    { label: "FY 21-22", value: "2021" },
    { label: "FY 22-23", value: "2022" },
]

const PaymentHeader: FC<PaymentHeaderProps> = (props) => {
    const { refreshList } = props;

    //const history = useHistory();

    const {
        paymentLoading,
        generatePaymentReport,
    } = PaymentService()

    const triggerRef = useRef<HTMLSpanElement>(null)

    const [showPaymentForm, setShowPaymentForm] = useState<boolean>(false);

    const paymentReportGeneration = async (values: { year: string }) => {
        if (!values.year) return
        const response = await generatePaymentReport(values)
        if (response)
            triggerRef?.current?.click()
    }

    const exportDropdown = <div className='export-dropdown__wrapper'
        onClick={e => e?.stopPropagation()}
    >
        <Formik
            initialValues={{ year: '' }}
            onSubmit={paymentReportGeneration}>
            {({ values, setFieldValue, submitForm }) => <Form
                onClick={e => e.stopPropagation()}
            >
                <Row align="bottom" gutter={[16, 16]}>
                    <Col span={16}>
                        <DropdownField
                            name={'year'}
                            title="Payment data for"
                            onClick={e => e?.stopPropagation()}
                            options={paymentYearOptions}
                            placeHolder="Select"
                            onChange={(val) => setFieldValue("year", val)}
                        />
                    </Col>
                    <Col span={8}>
                        <Button
                            type='primary'
                            onClick={submitForm}
                            disabled={!values.year}
                            loading={paymentLoading}
                        >
                            Send email
                        </Button>
                    </Col>
                </Row>
            </Form>}
        </Formik>
    </div>

    return (
        <div className="payment-header">
            <div className="header">
                <AppHeader title={"Payment"}
                    setFormVisible={setShowPaymentForm}
                />
                <div className="payment-actions">
                    {/* <span className="action-item" onClick={() => {
                        // history.push(appRoutes.ONE_TIME_PAYMENT)
                    }}>
                        <i className="icon-payment" />
                        <p>One Time Payment</p>
                    </span> */}
                    <Dropdown
                        overlay={exportDropdown}
                        trigger={["click"]}

                    >
                        <span className="action-item" ref={triggerRef}>
                            <i className="icon-rep" />
                            <p>Export data</p>
                        </span>
                    </Dropdown>
                </div>
            </div>
            <PaymentForm showModal={showPaymentForm}
                setShowModal={setShowPaymentForm}
                refreshList={refreshList}
            />
        </div>
    )
}

export default PaymentHeader;