export const ApiRoutes = {
    BASE_URL: process.env.REACT_APP_API_BASE_URL,
    USER_LOGIN: "users/sign_in",
    USER_LOGOUT: "users/sign_out",
    FORGOT_PASSWORD: "/forgot_password",
    RESET_PASSWORD: "/reset_password",
    PROJECTS: "admin/projects",
    PROJECT_ATTACHMENTS: "admin/project_attachments",
    PROJECT_DOCUMENTS: "admin/project_documents",
    PROJECT_SUGGESTIONS: "admin/project_suggestions",
    DASHBOARD_STATS: "admin/dashboard_stats",
    DONATION_STATS: "admin/donation_stats",
    DONOR_STATS: "admin/donor_stats",
    PAYMENTS: "admin/payments",
    SUBSCRIPTIONS: "admin/subscription_schemes",
    SUBSCRIPTION: "admin/subscription_schemes/:id",
    DONORS: "admin/donors",
    DONORS_FIND: "admin/donors/find",
    DONOR: "admin/donors/:donorId",
    DONOR_DEACTIVATE: "admin/donors/:donorId/deactivate",
    DONOR_PROMOTE: "admin/donors/:donorId/promote",
    DONOR_DEMOTE: "admin/area_representatives/:repId/demote",
    AREA_REPRESENTATIVES: "admin/area_representatives",
    INTERNAL_USERS: "admin/users",
    INTERNAL_USER: "admin/users/:userId",
    DONOR_PAYMENTS: "admin/donors/:donorId/payments",
    DONOR_GROUP_MEMBERS: "admin/donors/:donorId/members",
    DONOR_GROUP_MEMBERS_PROMOTE: "/admin/donors/:donorId/members/:memberId/promote_head",
    DONOR_GROUP_MEMBERS_INDIVIDUAL_DONOR: "/admin/donors/:donorId/members/:memberId/promote_donor",
    PAYMENT_OTP: "admin/payments/send_otp",
    BULK_ASSIGN_REP: "admin/donors/assign_representative",
    DONOR_SEARCH: "admin/donors/find",
    DONOR_SUBSCRIPTIONS: "admin/donors/:donorId/subscribed_projects",
    CONTACT_DETAILS_VIEW: "admin/admin_contacts/view",
    CONTACT_DETAILS_UPDATE: "admin/admin_contacts/update",
    DONOR_AREA_REPRESENTATIVES: "admin/area_representatives",
    PROJECT_BLOG_ATTACHMENTS: "admin/project_blog_attachments",
    PROJECT_BLOGS: "admin/projects/:projectId/project_blogs",
    PROJECT_SUBSCRIBERS: "admin/projects/:projectId/subscribers",
    META_CITIES: "/meta/cities",
    META_STATES: "/meta/states",
    META_COUNTRIES: "/meta/countries",
    GENDERS: "/meta/genders",
    IDENTIFICATION_CARDS: "/meta/identification_cards",
    PAYMENT_REPORTS: "/meta/payment_reports",
    PAYMENT: "/admin/payments/:id",
}
