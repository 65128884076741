import React, { FC, useState } from 'react'
import "./appTable.scss"
import { Col, Input, Row, Select, Table, DatePicker, TablePaginationConfig } from "antd";
import moment from "moment";
import { FilterValue, SorterResult, TableCurrentDataSource, TableRowSelection } from 'antd/lib/table/interface';
import useFilters from '../../utils/useFilters';

const { Option } = Select;

interface AppTableProps {
    data?: any;
    projectType?: string;
    title: string;
    handleSearch: Function;
    placeholder?: string;
    totalRecords?: number;
    columns: any;
    loading?: boolean;
    handleRedirect?: any;
    expandable?: any;
    showMonth?: boolean;
    hideFilter?: boolean;
    scroll?: any;
    rowKey?: (record: any) => string | number;
    handleMonthChange?: (dateString: string) => void;
    paymentFilter?: any;
    pagination?: TablePaginationConfig | false
    onChange?: (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<any> | SorterResult<any>[], extra: TableCurrentDataSource<any>) => void
    rowSelection?: TableRowSelection<any>
}

const AppTable: FC<AppTableProps> = (props) => {
    const { data, projectType, title, handleSearch, onChange, columns, paymentFilter, scroll, pagination = {
        defaultPageSize: 50,
    },
        rowSelection, rowKey, loading, totalRecords, handleRedirect, expandable, showMonth, hideFilter, handleMonthChange } = props;

    const [selectedMonth, setSelectedMonth] = useState<string>(moment().format('MMM, YYYY'));

    const {
        getParams,
    } = useFilters()

    const onMonthChange = (value: any, dateString: string) => {
        setSelectedMonth(dateString);
        if (handleMonthChange) handleMonthChange(dateString);
    }

    let timer: NodeJS.Timeout;
    const debounce = (search: string) => {
        if (timer)
            clearTimeout(timer)

        timer = setTimeout(() => {
            handleSearch(search)
        }, 800)
    }

    return (
        <div className="app-table">
            {!hideFilter && <Row justify="space-between" className="filters">
                <Col span={6}>
                    {!!totalRecords && <pre><span>{totalRecords ?? 0}</span> {projectType} {title}(s)</pre>}
                </Col>
                <Col span={18}>
                    {showMonth && <>
                        <div className="sort">
                            <p> <i className="icon-date" /> Month</p>
                        </div>
                        <DatePicker onChange={onMonthChange}
                            picker="month"
                            value={moment(selectedMonth, 'MMM, YYYY')}
                            allowClear={false}
                            format={'MMM, YYYY'}
                        />
                    </>}
                    {/*<div className="sort">
                        <p> <i className="icon-sort"/> Sort by</p>
                    </div>
                    <Select
                        defaultValue="lucy"
                        style={{ width: 120 }}
                        className="mr-3"
                        suffixIcon={<i className="icon-down" />}
                    >
                        <Option value="lucy">Lucy</Option>
                    </Select>*/}
                    {paymentFilter && <>
                        <div className="sort">
                            <p> <i className="icon-sort" /> Filter by</p>
                        </div>
                        {paymentFilter}
                    </>}
                    <Input
                        className="search-field"
                        placeholder="Search by reg no., name, phone number …."
                        size="small"
                        defaultValue={getParams(['search'])?.search || ''}
                        prefix={<i className="icon-search" />}
                        onPressEnter={(e: any) => handleSearch(e?.target?.value)}
                        onChange={(e) => {
                            debounce(e?.target?.value)
                            // !e?.target?.value && handleSearch()
                        }}
                    />
                </Col>
            </Row>
            }

            <Table dataSource={data}
                columns={columns}
                pagination={{
                    ...pagination,
                    showSizeChanger: false
                }}
                scroll={scroll}
                onRow={handleRedirect}
                loading={loading}
                onChange={onChange}
                expandable={expandable}
                rowSelection={rowSelection}
                rowKey={rowKey}
            />

            {/* <div className="table-footer">
                <p>Showing <span>{data?.length < 10 ? `1-${data?.length}` : `1-10`}</span> of <span>{data?.length}</span></p>
            </div> */}
        </div>
    )
}

export default AppTable;