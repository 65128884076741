import React, { FC } from 'react'
import "./paymentExpanded.scss"
import { Col, Row, Space } from "antd";
import moment from "moment";
import { PaymentModel } from "../../../models/Payment/payment.model";
import PaymentService from '../../../services/PaymentService/payment.service';
import { PaymentStatus } from '../../../enums/paymentStatus';
import { LoadingOutlined } from '@ant-design/icons';
import { Role } from '../../../enums/role';

interface PaymentExpandedProps {
    payment: PaymentModel;
}

const PaymentExpanded: FC<PaymentExpandedProps> = (props) => {
    const { payment } = props;

    const { fetchPayment, isReceiptLoading } = PaymentService();
    
    const isGroupMember = payment?.donor?.roleName === Role.GROUP_MEMBER;

    const isReceiptVisible = (!isGroupMember || (isGroupMember && payment?.isOnline)) && (payment?.status === PaymentStatus.PAID || payment?.status === PaymentStatus.AMOUNT_SETTLED);

    const handleReceiptClick = async () => {
        if(!payment?.id)    return;

        const paymentWithInvoice = await fetchPayment(payment?.id);
        
        if(paymentWithInvoice === false || !paymentWithInvoice?.url) return;

        const anchor = document.createElement("a");
        anchor.setAttribute("href", paymentWithInvoice?.url);
        anchor.setAttribute("target", "_blank");
        anchor.click();
    };

    return (
        <div className="payment-expanded">
            <div className="label">Payment Info</div>
            <div className="payment-expanded__info">
                <Row>
                    <Col span={4}>
                        <div className="payment-expanded__label font-bold">Date</div>
                        <div className="payment-expanded__value">{moment(payment?.date).format('DD MMM,YYYY')}</div>
                    </Col>
                    <Col span={4}>
                        <div className="payment-expanded__label font-bold">Mode</div>
                        <div className="payment-expanded__value">
                            {payment?.paidByRep ? 'Paid to rep' :
                                (payment?.isOnline ? 'Online' : 'Offline')
                            }
                        </div>
                    </Col>
                    <Col span={4}>
                        <div className="payment-expanded__label font-bold">Transaction ID</div>
                        <div className="payment-expanded__value">{payment?.transactionId ?? '-'}</div>
                    </Col>
                    <Col span={4}>
                        <div className="payment-expanded__label font-bold">Rep Name</div>
                        <div className="payment-expanded__value">{payment?.representative?.username ?? '-'}</div>
                    </Col>
                    <Col span={4}>
                        <div className="payment-expanded__label font-bold">Rep reg no</div>
                        <div className="payment-expanded__value">{payment?.representative?.regNumber ?? '-'}</div>
                    </Col>
                    {isReceiptVisible && <Col span={4}>
                        <div onClick={handleReceiptClick}>
                            <Space className="payment-expanded__action">
                                <i className="icon-recipt" /> VIEW RECEIPT
                                {isReceiptLoading && <LoadingOutlined />}
                            </Space>
                        </div>
                    </Col>}
                </Row>
            </div>
            {payment?.paymentGroupMembers && payment?.paymentGroupMembers?.length > 0 &&
                <>
                    <div className="label">Donation split</div>
                    <div className="payment-expanded__info">
                        {payment?.paymentGroupMembers?.map((groupRecord, i) =>
                            <Row key={i}>
                                <Col span={17}>
                                    <div className="payment-expanded__info__name">{groupRecord?.username}</div>
                                </Col>
                                <Col span={6}>
                                    <div className="payment-expanded__info__name font-semi-bold">
                                        ₹ {groupRecord?.grpMemberAmount}
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </div>
                </>}
        </div>
    )
}

export default PaymentExpanded;