import React, {FC, useEffect, useState} from 'react'
import "./representativeDetails.scss"
import {Col, Row, Tabs} from "antd";
import Back from "../../../shared/components/Back";
import userPlaceholder from "../../../assets/userPlaceholder.png";
import PaymentStats from "../../Payments/PaymentStats";
import ListDonors from "../../Donors/ListDonors";
import DonorDetails from "../../Donors/DonorDetails";
import DonorService from "../../../services/DonorService/donor.service";
import {useParams} from "react-router-dom";
import {PaymentStatsModel} from "../../../models/Payment/payment.model";

const { TabPane } = Tabs;

interface RepresentativeDetailsProps {

}

const RepresentativeDetails: FC<RepresentativeDetailsProps> = (props) => {
    const { } = props;

    const { id } = useParams();

    const [tab, setTab] = useState("rep-details");

    const handleChange = (activeKey: string) => {
        setTab(activeKey);
    };

    const randomStatus = 'done'; //Math.random() < 0.5 ? 'done' : 'pending';

    const {
        fetchDonor,
        donor
    } = DonorService()

    useEffect(() => {
        fetchDonor(id).then();
    }, [id])    

    return (
        <div className="representative-details">
            <Back name={"Representative"}/>

            <Tabs defaultActiveKey={tab}
                onChange={handleChange}
            >
                <TabPane tab={"Representative Details"}
                         key={"rep-details"}
                >
                    <div className="rep-name-image">
                        <img src={donor?.profilePicUrl ||userPlaceholder} alt={''}/>
                        <h1 className={"font-bold"}> {donor?.username} </h1>
                    </div>
                    <Row>
                        <Col span={19}>
                            <PaymentStats paymentStats={new PaymentStatsModel()}/>
                        </Col>
                        <Col span={4} offset={1}>
                            <div className={`rep-audit-status ${randomStatus}`}>
                                <div className={"font-light audit-header"}>Audit for the month</div>
                                <div className={"font-bold audit-status-info text-capitalize"}>
                                    {randomStatus} <i className={`${randomStatus === 'done' ? 'icon-paid' : 'icon-pending' }`}/>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <ListDonors listFor="representative"
                                showMonth={false}
                                representativeId={id}
                    />
                </TabPane>
                <TabPane tab={"Profile Details"}
                         key={"profile-details"}
                >
                   <DonorDetails hideBack/>
                </TabPane>
            </Tabs>
        </div>
    )
}

export default RepresentativeDetails;