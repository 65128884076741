import axiosInstance from "../../interceptor/axiosInstance";
import { deserialize, serialize } from "serializr";
import { User } from "../../models/user.model";
import { store } from "../../store";
import {AUTHENTICATED, UNAUTHENTICATED} from "../../store/definitions/authConstants";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { useState } from "react";
import { useHistory } from "react-router";
import { appRoutes } from "../../routes/routeConstants/appRoutes";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";

const UserService = () => {
  const history = useHistory();

  const [user, setUser] = useState<User>();

  const [error, setError] = useState<Error>();

  const [loading, setLoading] = useState(false);

  const loginUser = async (user: User) => {
    try {
        setLoading(true);
        const UserJSON = {
            user: serialize(user),
        };
        const response = await axiosInstance.post(ApiRoutes.USER_LOGIN, UserJSON);
        const userDetails = deserialize(User, response.data["user"]);
        store.dispatch({
            type: AUTHENTICATED,
            payload: {
              authenticated: true,
              user: userDetails,
            },
        });
        if(userDetails) localStorage.setItem("user",JSON.stringify(userDetails));

        localStorage.setItem("authHeaders",JSON.stringify(response.headers));

        Notification({
            message: "Login",
            description: "Logged in successfully",
            type: NotificationTypes.SUCCESS,
        });
        setUser(userDetails);
        history.push(appRoutes.DASHBOARD);
    } catch (error) {
      Notification({
        message: "Login failed",
        description: "incorrect email or password",
        type: NotificationTypes.ERROR,
      });
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const setResetCode = async (user: User) => {
    try {
      setLoading(true);
      const forgotPasswordJSON = {
        admin: serialize(user),
      };
      const response = await axiosInstance.post(
        ApiRoutes.FORGOT_PASSWORD,
        forgotPasswordJSON
      );
	  return true;
    } catch (error) {
      setError(error);
	  return false;
    } finally {
      setLoading(false);
    }
  };

  const resetPassword = async (user: User) => {
	try {
		setLoading(true);
		const forgotPasswordJSON = {
		  admin: serialize(user),
		};
		const response = await axiosInstance.post(
		  ApiRoutes.RESET_PASSWORD,
		  forgotPasswordJSON
		);
		return true;
	  } catch (error) {
		setError(error);
		return false;
	  } finally {
		setLoading(false);
	  }
  }

  const logoutUser = async () => {
	try {
		setLoading(true);
		axiosInstance.delete(
		  ApiRoutes.USER_LOGOUT,
		).then(()=>{
		    store.dispatch({
                type: UNAUTHENTICATED,
                payload: {
                    authenticated: false,
                    user: '',
                },
            });

            localStorage.clear();

            Notification({
                message: "Logout",
                description: "Logged out successfully",
                type: NotificationTypes.SUCCESS,
            });

            history.push(appRoutes.LOGIN);

        })

	  } catch (error) {
		setError(error);
		return false;
	  } finally {
		setLoading(false);
	  }
  }

  return {
    user,
    error,
    loading,
    loginUser,
    setResetCode,
	resetPassword,
    logoutUser
  };
};

export default UserService;
