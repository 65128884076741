import React, { FC, useEffect, useState } from 'react'
import "./internalUsers.scss"
import AppHeader from "../../shared/components/AppHeader";
import AppTable from "../../shared/components/AppTable";
import InternalUserForm from "./InternalUserForm";
import DeleteConfirmation from "../../shared/components/DeleteConfirmation";
import InternalUserService from "../../services/InternalUserService/internalUser.service";
import { User } from "../../models/user.model";
import Loader from '../../shared/components/Loader';
import AuthContainer from '../../store/container/AuthContainer';
import { AuthReducerProps } from '../../store/reducers/authReducer';
import useFilters from '../../shared/utils/useFilters';
import { TablePaginationConfig } from 'antd';

interface InternalUsersProps extends AuthReducerProps {

}

const InternalUsers: FC<InternalUsersProps> = (props) => {
    const {
        user
    } = props;

    const {
        getParams,
        setParams,
    } = useFilters()

    const { fetchInternalUsersList, internalUsersPagination, internalUsersSubmitting, internalUsersLoading,
        internalUsersList, deleteInternalUsers } = InternalUserService();

    const [showForm, setShowForm] = useState<boolean>(false);
    const [showDelete, setShowDelete] = useState<boolean>(false);
    const [currentUser, setCurrentUser] = useState<User>();

    useEffect(() => {
        fetchInternalUsersList({
            search: getParams(['search'])?.search || '',
            page: internalUsersPagination?.currentPage || getParams(['page'])?.page || 1
        }).then();
    }, [])

    useEffect(() => {
        if (!showForm) setCurrentUser(undefined)
    }, [showForm])

    const columns = [
        // {
        //     title: "S.NO",
        //     dataIndex: "id",
        //     key: "id",
        //     render: (_: any, __: any, index: number) => index + 1,
        //     // sorter: (a: any, b: any) => a.id - b.id,
        // },
        {
            title: "ID",
            dataIndex: "regNumber",
            key: "regNumber",
        },
        {
            title: "User Name",
            dataIndex: "username",
            key: "username",
        },
        {
            title: "Email ID",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Phone Number",
            dataIndex: "mobileNumber",
            key: "mobileNumber",
            render: (text: string, user: User) => <span>{user?.isdCode || ''}{(text || '')}</span>
        },
        {
            title: "Access",
            dataIndex: "access",
            key: "access",
            render: (text: string, record: User) =>
                <div className={"text-capitalize"}>
                    {record?.permissions && record?.permissions?.length > 2 ?
                        record.permissions.slice(0, 2).join(',') + ",+" + (record.permissions.length - 2)
                        : record.permissions?.join(',')}
                </div>

        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (text: string, record: User) =>
                <div className={`${record.status ? 'text-active' : 'text-inactive'}`}>
                    {record.status ? 'Active' : 'Inactive'}
                </div>
        },
        {
            title: "",
            dataIndex: "action",
            key: "action",
            render: (text: string, record: User) =>
                <div className="internal-user-listing__actions">
                    <span className="icon-edit" onClick={() => {
                        setShowForm(true)
                        setCurrentUser(record)
                    }} />
                    {user?.id !== record?.id && <span className="icon-delete" onClick={() => {
                        setShowDelete(true)
                        setCurrentUser(record)
                    }} />}
                </div>
        }
    ];

    const formVisiblityHandler = (visiblity: boolean, response?: boolean) => {
        setShowForm(visiblity)
        if (response)
            fetchInternalUsersList({ ...getParams(['search']), page: internalUsersPagination?.currentPage || getParams(['page'])?.page || 1 })
    }

    const deleteUserHandler = async () => {
        if (!currentUser?.id) return
        const response = await deleteInternalUsers(currentUser?.id);
        if (!response) return
        setCurrentUser(undefined)
        fetchInternalUsersList({ ...getParams(['search']), page: internalUsersPagination?.currentPage || getParams(['page'])?.page || 1 })
        setShowDelete(false)
    }

    const handleSearch = (search: string) => {
        setParams({ search, page: 1 })
        fetchInternalUsersList({ search, page: 1 })
    }

    const tableChangeHandler = (pagination: TablePaginationConfig) => {
        setParams({ ...getParams(['search']), page: pagination.current })
        fetchInternalUsersList({ ...getParams(['search']), page: pagination.current })
    }

    return (
        <div className="internal-users">
            <div className="header">
                <AppHeader title={"Internal users"}
                    setFormVisible={setShowForm}
                />
            </div>

            <AppTable title={"Internal users"}
                handleSearch={handleSearch}
                onChange={tableChangeHandler}
                columns={columns}
                data={internalUsersList}
                loading={internalUsersLoading}
                totalRecords={internalUsersPagination?.totalCount || 0}
                pagination={{
                    pageSize: 50,
                    current: internalUsersPagination?.currentPage || 1,
                    total: internalUsersPagination?.totalCount,
                    showTotal: (total: number, range: [number, number]) => <p>Showing <b>{` ${range[0]} - ${range[1]}`}</b> of <b>{`${total.toLocaleString()}`}</b></p>
                }}
            />

            <InternalUserForm showModal={showForm}
                setShowModal={formVisiblityHandler}
                user={currentUser}
            />

            <DeleteConfirmation showModal={showDelete}
                setShowModal={setShowDelete}
                title={"Internal user"}
                info={"Are you sure to delete the internal user? the user will be removed access to the platform"}
                name={currentUser?.username}
                email={currentUser?.email}
                mobileNumber={currentUser?.mobileNumber}
                loading={internalUsersSubmitting}
                handleDelete={deleteUserHandler}
            />
        </div>
    )
}

export default AuthContainer(InternalUsers);