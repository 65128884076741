import axiosInstance from "../../interceptor/axiosInstance";
import {ApiRoutes} from "../../routes/routeConstants/apiRoutes";
import {deserialize} from "serializr";
import {useState} from "react";
import {DashboardStatsModel, DonationStatsModel, DonorStatsModel} from "../../models/Dashboard/dashboardStats.model";

const DashboardService = () => {

    // not for the whole page, only to set the loader for the first card
    const [dashboardLoading, setDashboardLoading] = useState<boolean>(false);
    const [dashboardStats, setDashboardStats] = useState<DashboardStatsModel>( new DashboardStatsModel() );

    const [donorStatsLoading, setDonorStatsLoading] = useState<boolean>(false);
    const [donorStats, setDonorStats] = useState<Array<DonorStatsModel>>( [] );

    const [donationStatsLoading, setDonationStatsLoading] = useState<boolean>(false);
    const [donationInfo, setDonationInfo] = useState<DonationStatsModel>( new DonationStatsModel() );

    const fetchDashboardStats = async () => {
        try {
            setDashboardLoading(true);
            const { data } = await axiosInstance.get(ApiRoutes.DASHBOARD_STATS);
            const stats = deserialize(DashboardStatsModel, data);
            setDashboardStats(stats);
        } catch (error) {
        } finally {
            setDashboardLoading(false);
        }
    };

    const fetchDonorStats = async () => {
        try {
            setDonorStatsLoading(true);
            const { data } = await axiosInstance.get(ApiRoutes.DONOR_STATS);
            const stats:any = deserialize(DonorStatsModel, data['donor_stats']);
            setDonorStats(stats);
        } catch (error) {
        } finally {
            setDonorStatsLoading(false);
        }
    };

    const fetchDonationStats = async (year:number, cityId?:number) => {
        try {
            setDonationStatsLoading(true);
            const { data } = await axiosInstance.get(ApiRoutes.DONATION_STATS+`?year=${year}`);
            const stats:any = deserialize(DonationStatsModel, data);
            setDonationInfo(stats);
        } catch (error) {
        } finally {
            setDonationStatsLoading(false);
        }
    };

    return{
        fetchDashboardStats,
        dashboardStats,
        dashboardLoading,
        fetchDonorStats,
        donorStats,
        donorStatsLoading,
        fetchDonationStats,
        donationInfo,
        donationStatsLoading
    }
}

export default DashboardService;