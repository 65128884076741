import React from "react";
import Lottie from 'react-lottie';
import LoadingIcon from "./../../../assets/lotties/loader.json"
import "./loader.scss"

const Loader = () => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: LoadingIcon,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    return <div className="loader__container">
        <Lottie
            options={defaultOptions}
        />
    </div>
}

export default Loader