import { PaginationModel } from './../../models/Pagination/pagination.model';
import { generatePath } from 'react-router-dom';
import axiosInstance from "../../interceptor/axiosInstance";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { deserialize, serializable, serialize } from "serializr";
import { useState } from "react";
import { User } from "../../models/user.model";

const InternalUserService = () => {

    const [internalUsersLoading, setInternalUsersLoading] = useState<boolean>(false);
    const [internalUsersList, setInternalUsersList] = useState<User[]>([]);
    const [internalUsersPagination, setInternalUsersPagination] = useState<PaginationModel>();
    const [internalUsersSubmitting, setInternalUsersSubmitting] = useState<boolean>(false);

    const fetchInternalUsersList = async (params: {
        search?: string
        page?: number
        limit?: number
    } = {}) => {
        try {
            params.limit = 50
            setInternalUsersLoading(true);
            const { data } = await axiosInstance.get(ApiRoutes.INTERNAL_USERS, { params });
            const users: any = deserialize(User, data['users']);
            setInternalUsersList(users);
            setInternalUsersPagination(deserialize(PaginationModel, data))
        } catch (error) {
            console.log("internal user", error)
        } finally {
            setInternalUsersLoading(false);
        }
    };

    const createInternalUsers = async (user: User) => {
        try {
            setInternalUsersSubmitting(true);
            const payload = { user: serialize(User, user) }
            const { data } = await axiosInstance.post(ApiRoutes.INTERNAL_USERS, payload);
            return data
        } catch (error) {
            console.log("internal user", error)
        } finally {
            setInternalUsersSubmitting(false);
        }
    };

    const updateInternalUsers = async (user: User) => {
        try {
            setInternalUsersSubmitting(true);
            const payload = { user: serialize(User, user) }
            const API_URL = generatePath(ApiRoutes.INTERNAL_USER, { userId: user?.id })
            const { data } = await axiosInstance.put(API_URL, payload);
            return data
        } catch (error) {
            console.log("internal user", error)
        } finally {
            setInternalUsersSubmitting(false);
        }
    };

    const deleteInternalUsers = async (userId: number) => {
        try {
            setInternalUsersSubmitting(true);
            const API_URL = generatePath(ApiRoutes.INTERNAL_USER, { userId })
            await axiosInstance.delete(API_URL);
            return true
        } catch (error) {
            console.log("internal user", error)
        } finally {
            setInternalUsersSubmitting(false);
        }
    };

    return {
        internalUsersPagination,
        fetchInternalUsersList,
        internalUsersList,
        createInternalUsers,
        updateInternalUsers,
        deleteInternalUsers,
        internalUsersSubmitting,
        internalUsersLoading
    }
}

export default InternalUserService;