import { combineReducers } from "redux";
import authReducer, { AuthState } from "./authReducer";
import projectReducer, {ProjectState} from "./projectReducer";

export interface RootReducerProps {
    auth: AuthState;
    project: ProjectState;
}

const RootReducer = combineReducers<RootReducerProps>({
    auth: authReducer,
    project: projectReducer
});

export default RootReducer;