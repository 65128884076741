import React, { FC } from 'react'
import "./projectSuggestions.scss"
import {Modal, Popover} from "antd";
import ProjectService from "../../../../services/ProjectService/project.service";

interface ProjectSuggestionsProps {
    showModal: boolean;
    setShowModal: Function;
}

const ProjectSuggestions: FC<ProjectSuggestionsProps> = ({showModal, setShowModal}) => {

    const { suggestedProjects, updateSuggestedProject } = ProjectService()

    const projectSuggestionsPopover = (
        <div className="popover-content" style={{ cursor: "pointer" }}>
            <i className="icon-delete-forever"/>
            Remove all suggestions
        </div>
    );

    return (
        <div className="project-suggestions">
            <Modal title={
                <div className="modal-title">
                    <h2>Project Suggestions</h2>
                    <div className="modal-controls">
                        <Popover placement="bottomRight" content={projectSuggestionsPopover} trigger="click">
                            <i className="icon-more" />
                        </Popover>
                        <i className="icon-close" onClick={()=>setShowModal(false)} />
                    </div>
                </div>
            }
                   visible={showModal}
                   onCancel={()=>setShowModal(false)}
                   className="project-suggestions-modal"
            >
                {
                    suggestedProjects?.map((project) => (
                        <div className="suggested-project">
                            <div className="suggested-project__details">
                                <div className="header">
                                    <h2>
                                        {project.templeName}
                                    </h2>
                                    <Popover placement="bottomRight"
                                             content={<div className="popover-content">
                                                 <div className="" onClick={() => updateSuggestedProject(`${project.id}`, "approve")}>
                                                     <i className="icon-add-2"/>
                                                     Add suggestion
                                                 </div>
                                                 <div className="" onClick={() => updateSuggestedProject(`${project.id}`, "reject")}>
                                                     <i className="icon-delete"/>
                                                     Remove suggestion
                                                 </div>
                                             </div>}
                                             trigger="click">
                                        <i className="icon-more" />
                                    </Popover>
                                </div>
                                <div className="info">
                                    <h3>Thirumanacheri, Chennai</h3>
                                    <h3> <i className="icon-profile"/> {project.representativeDetails?.username}</h3>
                                    <h3> <i className="icon-phone"/> {project.representativeDetails?.mobileNumber}</h3>
                                </div>
                                <div className="images">
                                    {
                                        project?.projectAttachments?.map((attachment) => (
                                            <>
                                                <img src={attachment.imageUrl} alt="" />
                                                <img src={attachment.imageUrl} alt="" />
                                                <img src={attachment.imageUrl} alt="" />
                                                <img src={attachment.imageUrl} alt="" />
                                            </>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="suggested-project__person">
                                <p>Suggested by</p>
                                <div className="person__card">
                                    <div className="image">
                                        <img src="" alt="" />
                                    </div>
                                    <div className="info">
                                        <h3>Senthil Kumaran</h3>
                                        <p>CHNSETH523423</p>
                                        <div className="">
                                            <p> <i className="icon-location"/> Chennai</p>
                                            <p> <i className="icon-phone"/>+91 9029301293</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </Modal>
        </div>
    )
}

export default ProjectSuggestions;