import React, { FC, useEffect } from 'react'
import "./representatives.scss"
import AppHeader from "../../shared/components/AppHeader";
import userPlaceholder from "../../assets/userPlaceholder.png";
import AppTable from "../../shared/components/AppTable";
import { generatePath } from "react-router-dom";
import { appRoutes } from "../../routes/routeConstants/appRoutes";
import { useHistory } from "react-router";
import AreaRepService from "../../services/AreaRepService/areaRep.service";
import { DonorModel, UserModel } from "../../models/Donor/donor.model";
import useFilters from '../../shared/utils/useFilters';
import { TablePaginationConfig } from 'antd';

interface RepresentativesProps {

}

const Representatives: FC<RepresentativesProps> = (props) => {
    const { } = props;

    const history = useHistory();

    const {
        setParams,
        getParams,
    } = useFilters()

    const { fetchAreaRepList, repPagination, areaRepLoading, areaRepList } = AreaRepService();

    useEffect(() => {
        fetchAreaRepList({
            search: getParams(["search"])?.search || '',
            page: getParams(["page"])?.page || 1,
        }).then()
    }, [])

    const columns = [
        // {
        //     title: "S No",
        //     dataIndex: "id",
        //     key: "id",
        //     width: 70,
        //     render: (text: string, record: UserModel) => (areaRepList.indexOf(record) + 1)
        //     //render: (_: any, __: any, index: number) => index + 1,
        // },
        {
            title: "Donor ID",
            dataIndex: "regNumber",
            key: "regNumber",
            width: 100,
        },
        {
            title: "Name",
            dataIndex: "username",
            key: "username",
            width: 150,
            ellipsis: true,
        },
        {
            title: "Mobile",
            dataIndex: "mobileNumber",
            key: "mobileNumber",
            width: 150,
            render: (text: String, record: DonorModel) => <span>{`${record.isdCode || ''}-${record.mobileNumber || ''}`}</span>
        },
        {
            title: "Donor #",
            dataIndex: "donorsCount",
            key: "donorsCount",
            width: 100,
        },
        {
            title: "Country",
            dataIndex: ["country", "name"],
            key: "country",
            width: 60,
            ellipsis: true,
        },
        {
            title: "State",
            dataIndex: ["state", "name"],
            key: "state",
            width: 60,
            ellipsis: true,
        },
        {
            title: "District",
            dataIndex: ["city", "name"],
            key: "city",
            width: 90,
            ellipsis: true,
        },
        {
            title: "Pincode",
            dataIndex: "pinCode",
            key: "pinCode",
            width: 80,
        },
        // {
        //     title: "Audit",
        //     dataIndex: "audit",
        //     key: "audit",
        //      width: 100,
        //     render: (text:string,record:UserModel) =>
        //        <i className="icon-paid done"/>,
        //     /*<i className={`${ record?.auditStatus ? "icon-paid done" : "icon-pending pending" }`}/>,*/
        //     filterDropdown: () => <div className="audit-info">
        //         <div>Column represents the audit for the month</div>
        //         <div><i className="icon-paid done"/> Done</div>
        //         <div><i className="icon-pending pending"/> Pending</div>
        //     </div>,
        //     filterIcon: ()=> <div><div className={'icon-info'}/></div>
        // },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            width: 90,
            render: (text: string, record: any) =>
                <span className={`${record?.status ? 'text-active' : 'text-inactive'}`}>
                    {record?.status ? 'Active' : 'Inactive'}
                </span>
        },
    ];

    const handleRowClick = (donor: DonorModel) => ({
        onClick: () => {
            history.push(generatePath(appRoutes.REPRESENTATIVE_DETAILS, {
                id: donor.id
            }))
        },
    });

    const handleSearch = (search: string) => {
        setParams({ search, page: 1 })
        fetchAreaRepList({ search, page: 1 })
    }

    const tableChangeHandler = (pagination: TablePaginationConfig) => {
        setParams({ ...getParams(['search']), page: pagination.current })
        fetchAreaRepList({
            page: pagination.current,
        });
    }

    return (
        <div className="representatives">
            <div className="header">
                <AppHeader title={"Area Representative"}
                    setFormVisible={() => { }}
                    disableAdd={true}
                />
            </div>

            <AppTable title={"Area Representative"}
                scroll={{ x: true }}
                handleSearch={handleSearch}
                columns={columns}
                data={areaRepList}
                onChange={tableChangeHandler}
                loading={areaRepLoading}
                totalRecords={repPagination?.totalCount || 0}
                handleRedirect={handleRowClick}
                pagination={{
                    pageSize: 50,
                    current: repPagination?.currentPage || 1,
                    total: repPagination?.totalCount,
                    showTotal: (total: number, range: [number, number]) => <p>Showing <b>{` ${range[0]} - ${range[1]}`}</b> of <b>{`${total.toLocaleString()}`}</b></p>
                }}
            />
        </div>
    )
}

export default Representatives;