import * as Yup from "yup";

export const createPaymentValidation = Yup.object().shape({
    mobileNumber: Yup.string()
        .min(8, "Mobile number should be valid")
        .max(15, "Mobile number should be valid")
        .required("Mobile number is required"),
    email: Yup.string()
        .email("Invalid email"),
    donorName: Yup.string().required("Donor name is required"),
    amount: Yup.string().required("Amount is required").nullable(),
    paymentMode: Yup.string().required("Payment mode is required").nullable(),
    countryId: Yup.string().required("Country is required"),
    cityId: Yup.string().when(['countryName'], (countryName:string, schema:any) => {
        if(countryName&&countryName?.toLowerCase()?.includes('india'))
        return schema.required("City is required!")
        return schema;
    }),
    stateId: Yup.string().when(['countryName'], (countryName:string, schema:any) => {
        if(countryName&&countryName?.toLowerCase()?.includes('india'))
        return schema.required("State is required!")
        return schema;
    }),
    pan: Yup.string()
        .nullable()
        .min(10, "PAN Number should have 10 digits")
        .max(10, "PAN Number should have 10 digits"),
    transactionId: Yup.string().when("paymentMode", {
        is: 'online',
        then: Yup.string().required("Transaction id is required")
    }),
})

export const otpValidation = Yup.object().shape({
    otp: Yup.string().required("OTP is required"),
})