import React, { FC } from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import { appRoutes } from "./routeConstants/appRoutes";
import AuthWrapper from "../views/Auth/AuthWrapper";
import isAuthenticated from "../shared/components/HOC/requireAuth";
import { RouterProps } from "../shared/types/route.type";
import ProjectDetails from "../views/Projects/ProjectDetails";
import ListProjects from "../views/Projects/ListProjects";
import ListDonors from "../views/Donors/ListDonors";
import SideMenu from "../shared/components/SideMenu";
import Payments from "../views/Payments";
import OneTimePayment from "../views/Payments/OneTimePayment";
import InternalUsers from "../views/InternalUsers";
import Subscriptions from "../views/Subscriptions";
import Representatives from "../views/Representatives";
import RepresentativeDetails from "../views/Representatives/RepresentativeDetails";
import DonorDetails from "../views/Donors/DonorDetails";
import Dashboard from "../views/Dashboard";

export const appHistory = createBrowserHistory();

const AppRoutes = () => {
  const routes: RouterProps[] = [
    { exact: false, path: appRoutes.AUTH, component: AuthWrapper },
    { exact: true, path: appRoutes.PROJECTS, component: isAuthenticated(ListProjects) },
    { exact: true, path: appRoutes.PROJECT_DETAILS, component: isAuthenticated(ProjectDetails) },
    { exact: true, path: appRoutes.DONORS, component: isAuthenticated(ListDonors) },
    { exact: true, path: appRoutes.PAYMENT, component: isAuthenticated(Payments) },
    { exact: true, path: appRoutes.ONE_TIME_PAYMENT, component: isAuthenticated(OneTimePayment) },
    { exact: true, path: appRoutes.INTERNAL_USERS, component: isAuthenticated(InternalUsers) },
    { exact: true, path: appRoutes.SUBSCRIPTION, component: isAuthenticated(Subscriptions) },
    { exact: true, path: appRoutes.DASHBOARD, component: isAuthenticated(Dashboard) },
    { exact: true, path: appRoutes.REPRESENTATIVE, component: isAuthenticated(Representatives) },
    { exact: true, path: appRoutes.REPRESENTATIVE_DETAILS, component: isAuthenticated(RepresentativeDetails) },
    { exact: true, path: appRoutes.DONOR_DETAILS, component: isAuthenticated(DonorDetails) },
  ];

  return (
    <div>
      <Router history={appHistory}>
        <SideMenu />
        <Switch>
          {routes.map((route, index) => (
            <Route key={index} {...route} component={route.component as FC} />
          ))}
          <Route path={"*"} render={() => <Redirect to={appRoutes.PROJECTS} />} />
        </Switch>
      </Router>
    </div>
  );
};

export default AppRoutes;
