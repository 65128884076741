import React, { FC } from 'react'
import "./inviteDonor.scss"
import { Form, Formik } from "formik";
import InputField from "../../../../../shared/components/InputField";
import { Button, Modal } from "antd";
import { inviteDonorValidation } from "./inviteDonorValidation";

interface InviteDonorProps {
    showModal: boolean;
    setShowModal: Function;
}

const InviteDonor: FC<InviteDonorProps> = ({ showModal, setShowModal }) => {

    const initialValues = {
        emailId: "",
    }

    const handleSubmit = (values: any) => {
    }

    return (
        <div className="invite-donor">
            <Modal title={
                <div className="modal-title">
                    <h2>Invite Donor</h2>
                    <i className="icon-close" onClick={() => setShowModal(false)} />
                </div>
            }
                visible={showModal}
                onCancel={() => setShowModal(false)}
                className="create-project-modal create-payment-modal"
                centered
            >
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={inviteDonorValidation}
                >
                    {({ isValid }) => {
                        return (
                            <Form className="create-project-form create-internal-user">
                                <div className="form-field">
                                    <InputField type="email"
                                        name="emailId"
                                        placeholder="Type email"
                                        title="Email Id"
                                    />
                                    <div className="sub-title mt-3">
                                        <i className={"icon-info"} />
                                        <span className={"ml-3"}>
                                            User will receive a invitation to the specified email
                                        </span>
                                    </div>
                                </div>
                                <div className="form-field">
                                    <Button type="primary"
                                        htmlType="submit"
                                        loading={false}
                                        disabled={!isValid}
                                    >
                                        Send invitation
                                    </Button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </Modal>
        </div>
    )
}

export default InviteDonor;